import "./App.css";
import i18n from "./i18n/i18n";
import { I18nextProvider } from "react-i18next";
import Routing from "./Routing/Routing";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div>
      <I18nextProvider i18n={i18n}>
        <Routing />
      </I18nextProvider>
      <ToastContainer transition={Slide} />
    </div>
  );
}

export default App;
