import React from "react";
import loader from "../../Assests/Images/Loading_Spinner.gif";
import styles from "./LoaderComponent.module.css";

function Loader() {
  return (
    <div className={styles.loader}>
      <img src={loader} alt="loading" />
    </div>
  );
}

export default Loader;
