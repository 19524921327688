import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "../Components/Home/Home.js";
import Login from "../Components/AuthComponent/LoginPage.js";
import ForgetPassword from "../Components/AuthComponent/ForgetPassword.js";
import CustomFormBackground from "../Components/CustomComponent/CustomFormBackground/CustomFormBackground.js";
import SignupPage from "../Components/AuthComponent/SignupPage.js";
import ResetPassword from "../Components/AuthComponent/ResetPassword.js";
import Sidebar from "../Components/Sidebar/Sidebar.js";
import Site from "../Components/AddSite/Site.js";
import CareGiver from "../Components/CareGiver/CareGiver.js";
import ShowPerson from "../Components/AddPerson/ShowPerson.js";
import TagList from "../Components/TagList/TagList.js";
import StakeholderDetail from "../Components/StakeholderDetail/StakeholderDetail.js";
import Stakeholder from "../Components/Stakeholder/Stakeholder.js";
import { useSelector } from "react-redux";
import StackholderProfile from "../Components/StackholderProfile/StackholderProfile.js";
import Level from "../Components/Settings/Level/Level.js";
import DeviceList from "../Components/DeviceList/DeviceList.js";
import TimeMessage from "../Components/Settings/TimeMessage/TimeMessage.js";
import Relation from "../Components/Settings/Relation/Relation.js";
import LocationMessage from "../Components/Settings/LocationMessage/LocationMessage.js";
import UpdateProfile from "../Components/StackholderProfile/UpdateProfile.js";
import Event from "../Components/Event/Event.js";
import EventType from "../Components/Settings/Eventtype/EventType.js";
import ShowDailyEvents from "../Components/DailyEvents/ShowDailyEvents.js";
import CareGiverDetails from "../Components/CareGiverDetails/CareGiverDetails.js";
import AssignPerson from "../Components/AssignPerson/AssignPerson.js";
import PrivacyPolicy from "../Components/ContentPage/PrivacyPolicy.js";
import TermsCondtions from "../Components/ContentPage/Terms&Conditions.js";
import PersonDetail from "../Components/AddPerson/PersonDetail.js";
import DailyEventType from "../Components/Settings/DailyEventType/DailyEventType.js";
import LocationType from "../Components/Settings/LocationType/LocationType.js";
import SubLocationType from "../Components/Settings/SubLocationType/SubLocationType.js";

function Routing() {
  const token = useSelector((state) => state.user.userToken);

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/" element={<CustomFormBackground />}>
            <Route path="/" element={<Navigate to="/signin" />} />
            <Route path="signin" element={<Login />} />
            <Route path="forget-password" element={<ForgetPassword />} />
            <Route path="reset-password" element={<ResetPassword />} />
          </Route>

          {!token && <Route path="/signup" element={<SignupPage />} />}

          <Route path="*" element={<Navigate to="/site" />} />

          {/* <Route path="/" element={<Sidebar />}> */}

          <Route
            path="/"
            element={
              token ? <Sidebar /> : <Navigate to="/signin" replace={true} />
            }
          >
            <Route path="/stakeholder" element={<Stakeholder />} />
            <Route path="/assignperson" element={<AssignPerson />} />

            <Route path="/stackholder-detail" element={<StakeholderDetail />} />

            <Route path="/" element={<Navigate to="/site" />} />
            <Route path="site" element={<Site />} />
            <Route path="caregiver" element={<CareGiver />} />
            <Route path="caregiverdetails" element={<CareGiverDetails />} />
            <Route path="person" element={<ShowPerson />} />
            <Route path="tag" element={<TagList />} />
            {/* <Route path="profile" element={<StackholderProfile />} /> */}
            <Route path="/setting/event-type" element={<EventType />}></Route>
            <Route
              path="/setting/daily-event-type"
              element={<DailyEventType />}
            ></Route>
            <Route path="profile" element={<StackholderProfile />} />
            <Route path="update-profile" element={<UpdateProfile />} />
            {/* <Route path="setting" element={<EventType />}></Route> */}
            <Route path="event" element={<Event />}></Route>
            <Route path="daily-event" element={<ShowDailyEvents />}></Route>
            <Route path="person-detail" element={<PersonDetail />}></Route>

            <Route path="/setting/level" element={<Level />}></Route>
            <Route
              path="/setting/location-type"
              element={<LocationType />}
            ></Route>
            <Route
              path="/setting/Sub-location-type"
              element={<SubLocationType />}
            ></Route>
            <Route
              path="/setting/time-message"
              element={<TimeMessage />}
            ></Route>
            <Route
              path="/setting/location-message"
              element={<LocationMessage />}
            ></Route>
            <Route path="/setting/relation" element={<Relation />}></Route>
            <Route path="device-list" element={<DeviceList />} />
          </Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route path="/terms-conditions" element={<TermsCondtions />}></Route>
          {/* <Route path="/person-detail" element={<PersonDetail />}></Route> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default Routing;
