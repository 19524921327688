import React, { useEffect, useState } from "react";
import IndependicareApi from "../../Helpers/Api";
import { STATUS_CODE } from "../../Utils/StatusCode";
import { useNavigate } from "react-router-dom";
import { Image, Row, Col } from "react-bootstrap";
import CustomButton from "../CustomComponent/CustomButton/CustomButton";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import style from "./StakeholderProfile.module.css";
import userDummmy from "../../Assests/Images/userDummy.jpeg";
import { t } from "i18next";
import Loader from "../../Components/LoaderComponent/LoaderComponent";
import { removeExtraPlusSign } from "../../Utils/Common";
import { clearUserState, getProfile } from "../../redux/slices/userSlice";
import { useDispatch } from "react-redux";
import Toster from "../../Toster/Toster";
import { STATUS_MSG } from "../../Utils/StatusMessage";

function StackholderProfile() {
  // const [profileData,setProfileData]=useState('')
  // const userToken = localStorage.getItem('userToken')
  const [isLoading, setIsLoading] = useState(false);
  const [profileData, setProfileData] = useState("");
  const dispatch = useDispatch();

  const token = useSelector((state) => state.user.userToken);
  const usertype = useSelector((state) => state.user.userType);

  const navigate = useNavigate();

  const handleGetProfile = () => {
    setIsLoading(true);
    dispatch(getProfile(token)).then((res) => {
      setIsLoading(false);
      var response = res.payload;
      const ResponseCode = STATUS_MSG[response?.data?.code];
      if (response?.code === STATUS_CODE.SUCCESS) {
        setProfileData(response?.data);
      } else if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
      } else if (response?.data?.code == STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(response?.data?.message, "error");
      } else {
        Toster(t(ResponseCode), "error");
      }
    });
  };

  useEffect(() => {
    handleGetProfile();
  }, []);

  const handleUpdateSubmit = (event) => {
    //   setFormData(profileData);
    navigate("/update-profile", { state: { profileData: profileData } });
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className={style.profileProperty}>
        <Container>
          <h1 className={style.userProfiile}>{t("MY_PROFILE")}</h1>
          <div className={style.getUserProfile}>
            <Row>
              <Col sm={12} md={6} lg={4}>
                <p className={style.paragraphInformation}>{t("BASIC_INFO")}</p>
                <div className={style.sectionContentPropertyBasic}>
                  <div>
                    <li>
                      <Image
                        className={style.imageStyleProperty}
                        src={
                          profileData.profile_img
                            ? profileData.profile_img
                            : userDummmy
                        }
                        alt="image"
                      />
                    </li>
                    <li className={style.userNameProperty}>
                      {profileData.name}
                    </li>
                    <li className={style.userEmailProperty}>
                      {profileData.email}
                    </li>
                    <li className={style.userContactProperty}>
                      {profileData?.dial_code
                        ? removeExtraPlusSign(profileData.dial_code)
                        : ""}{" "}
                      {profileData.phone_number}
                    </li>
                  </div>
                </div>
              </Col>
              <Col sm={12} md={6} lg={8} className={style.otherDetailProperty}>
                <p className={style.paragraphInformation}>{t("OTHER_INFO")}</p>
                <div className={style.sectionContentPropertyOther}>
                  <div className={style.OtherInformationProperty}>
                    <li>
                      {t("STREET_ADDRESS")} :{" "}
                      <span>{profileData.street_address}</span>
                    </li>
                    {profileData.street_address_line2 && (
                      <li>
                        {t("STREET_ADDRESS_2")} :{" "}
                        <span>{profileData.street_address_line2}</span>
                      </li>
                    )}
                    <li>
                      {t("Country")} : <span>{profileData.country_name}</span>
                    </li>
                    <li>
                      {t("STATE")} : <span>{profileData.state_name}</span>
                    </li>
                    <li>
                      {t("CITY")} : <span>{profileData.city}</span>
                    </li>
                    <li>
                      {t("ZIP_POSTAL_CODE")} :{" "}
                      <span>{profileData.zipcode}</span>
                    </li>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className={style.buttonContainer}>
            <CustomButton
              type="submit"
              value="Edit Profile"
              onClick={() => handleUpdateSubmit(profileData)}
              className={style.buttonProperty}
            />
          </div>
        </Container>
      </div>
    </>
  );
}
export default StackholderProfile;
