import React, { useEffect, useState } from "react";
import styles from "../../Components/ContentPage/PrivacyPolicy.module.css";
import { useTranslation } from "react-i18next";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import IndependicareApi from "../../Helpers/Api";
import { STATUS_CODE } from "../../Utils/StatusCode";
import { STATUS_MSG } from "../../Utils/StatusMessage";
import Toster from "../../Toster/Toster";
import { Navigate, useNavigate } from "react-router-dom";
import { clearUserState } from "../../redux/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import DOMPurify from "dompurify";
import Loader from "../../Components/LoaderComponent/LoaderComponent";
import { Row } from "react-bootstrap";

function PrivacyPolicy() {
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.userToken);

  useEffect(() => {
    handelGetData();
  }, []);

  const handelGetData = () => {
    setIsLoading(true);
    IndependicareApi.privacyPolicy(token, "privacy_policy").then((res) => {
      setIsLoading(false);
      const responseCode = STATUS_MSG[res?.data?.code];
      if (res?.code === STATUS_CODE.SUCCESS) {
        setContent(res.data.content_data);
        setIsLoading(false);
      } else if (
        res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        res?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
      } else if (res?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(res?.data?.message, "error");
      } else {
        Toster(t(responseCode), "error");
      }
    });
  };
  return (
    <>
      {isLoading && <Loader />}
      <Header />
      <div className="container">
        <Row>
          <div className="col-lg-10" style={{ margin: "0px auto" }}>
            <div className={styles.privacyPolicy}>
              <h4>{t("PRIVACY_POLICY")}</h4>
              <div className={styles.policyText}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(content),
                  }}
                ></div>
              </div>
            </div>
          </div>
        </Row>
      </div>
      <Footer />
    </>
  );
}
export default PrivacyPolicy;
