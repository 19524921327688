import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { CustomInputFields } from "../../CustomComponent/CustomInputFields/CustomInputField";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import IndependicareApi from "../../../Helpers/Api";
import { STATUS_MSG } from "../../../Utils/StatusMessage";
import { clearUserState } from "../../../redux/slices/userSlice";
import { STATUS_CODE } from "../../../Utils/StatusCode";
import Toster from "../../../Toster/Toster";
import { useNavigate } from "react-router-dom";
import styles from "../Eventtype/Event.module.css";
import CancelIcon from "@mui/icons-material/Cancel";
import { EVENT_TYPE } from "../../../Utils/Constant";
import Loader from "../../LoaderComponent/LoaderComponent";

export default function EventModal({
  isShowModal,
  setIsShowModal,
  isEdit,
  setIsEdit,
  handelGetDailyEvents,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.userToken);
  const {
    register,
    handleSubmit,
    reset,
    formState,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isEdit && isShowModal.id) {
      IndependicareApi.detailEvent(token, isShowModal.id).then((response) => {
        setValue("title", response?.data?.title);
      });
    } else {
      setValue("title", "");
    }
  }, [isEdit, isShowModal.id]);

  const handleAddEvent = (postData) => {
    IndependicareApi.addEvent(postData, token).then((response) => {
      const ResponseCode = STATUS_MSG[response?.data.code];

      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("session expired"), "error");
        navigate("/signin");
      } else {
        if (response?.code == STATUS_CODE.SUCCESS) {
          Toster(t("ADD_EVENT_TYPE_SUCCESSFULLY"), "success");
          handleCloseModal();
          handelGetDailyEvents();
          reset();
          setIsLoading(false);
        } else if (
          response?.code === STATUS_CODE.VALIDATIONS_ERROR ||
          response?.data?.code === STATUS_CODE.VALIDATIONS_ERROR
        ) {
          Toster(response?.data?.message, "error");
          setIsLoading(false);
          handleCloseModal();
        } else {
          Toster(t(ResponseCode), "error");
          setIsLoading(false);
          handleCloseModal();
        }
      }
    });
  };

  const handleUpdateEvent = (postData) => {
    IndependicareApi.updateEvent(postData, token, isShowModal.id).then(
      (response) => {
        const ResponseCode = STATUS_MSG[response?.data.code];

        if (
          response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODE.INACTIVE_USER
        ) {
          dispatch(clearUserState());
          Toster(t("session expired"), "error");
          navigate("/signin");
        } else {
          if (response?.code == STATUS_CODE.SUCCESS) {
            Toster(t("EVENT_TYPE_UPDATE_SUCCESSFULLY"), "success");
            handleCloseModal();
            handelGetDailyEvents();
            reset();
            setIsLoading(false);
          } else if (
            response?.code === STATUS_CODE.VALIDATIONS_ERROR ||
            response?.data?.code === STATUS_CODE.VALIDATIONS_ERROR
          ) {
            Toster(response?.data?.message, "error");
            setIsLoading(false);
            handleCloseModal();
          } else {
            Toster(t(ResponseCode), "error");
            setIsLoading(false);
            handleCloseModal();
          }
        }
      }
    );
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    const postData = new FormData();
    postData.append("title", data.title);
    postData.append("event_type", EVENT_TYPE.DAILY_EVENT);
    isEdit ? handleUpdateEvent(postData) : handleAddEvent(postData);
  };

  const handleCloseModal = () => {
    setIsShowModal((prev) => ({ ...prev, show: false }));
    reset();
    setIsEdit(false);
  };

  return (
    <Modal show={isShowModal.show} onHide={handleCloseModal}>
      {isLoading && <Loader />}
      <Modal.Header className="d-flex justify-content-between">
        <p className={styles.Eventproperty}>{t("DAILY_EVENT_TYPE")}</p>
        <CancelIcon className="closeIconProperty" onClick={handleCloseModal} />
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <CustomInputFields
            type="text"
            PlaceHolder={t("ENTER_TITLE")}
            RegisterName={"title"}
            register={register}
            formState={formState}
            label={t("TITLE")}
            setValue={setValue}
          />
          <Button type="submit" className="AddSiteSubmitButton">
            {!isEdit ? t("DONE") : t("UPDATE")}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
