import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Dropdown from "react-bootstrap/Dropdown";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import edit from "../../Assests/Images/edit.png";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useTranslation } from "react-i18next";
import {
  Row,
  Col,
  Container,
  Table,
  Image,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
// import AddEvent from "../Event/AddEvent";
import CancelIcon from "@mui/icons-material/Cancel";
import CustomSearchField from "../../Components/CustomComponent/CustomSearchField/CustomSearchField";
import IndependicareApi from "../../Helpers/Api";
import { useSelector } from "react-redux";
import { STATUS_MSG } from "../../Utils/StatusMessage";
import { STATUS_CODE } from "../../Utils/StatusCode";
import Toster from "../../Toster/Toster";
import { clearUserState } from "../../redux/slices/userSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import style from "./ShowDailyEvents.module.css";
import useDebounce from "../../Helpers/Debounce";
import Loader from "../LoaderComponent/LoaderComponent";

import { DATE_FORMATES } from "../../Utils/Constant";
import Pagination from "../CustomComponent/CustomPagination/Pagination";
import AddDailyEvents from "./AddDailyEvents";
import DailyEventStatus from "./DailyEventStatus";
// import EventStatus from "./EventStatus";
import deleteImage from "../../Assests/Images/delete_image.png";

import styles from "./ShowDailyEvents.module.css";

const ShowDailyEvents = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [showModalStatus, setShowModalStatus] = useState(false);

  const [showModalDelete, setShowModalDelete] = useState(false);
  const [eventIdToDelete, setEventIdToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const [eventData, setEventData] = useState([]);
  const [eventItem, setEventItem] = useState("");

  const [eventType, setEventType] = useState(`
daily-event
`);

  const token = useSelector((state) => state.user.userToken);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [addedCaregiverListCount, setAddedCaregiverListCount] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const tdAlignClass = { flex: 1, alignContent: "center" };

  const pageLimit = 10;

  const debouncedSearch = useDebounce((term) => {
    getEventList(1, term, 1);
    if (term == "") {
      setCurrentPage(1);
    }
  }, 500);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    getEventList(pageNumber, searchQuery);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEventItem(null);
  };

  const handleShowModal = (item = null) => {
    setShowModal(true);
  };
  // for delete event model form
  const handleCloseModalDelete = () => {
    setShowModalDelete(false);
  };
  const handleCloseModalStatus = () => {
    setShowModalStatus(false);
    setSelectedStatus(null);
  };

  const handleShowModalStatus = (item) => {
    setSelectedStatus(item);
    setShowModalStatus(true);
  };

  const getEventList = (page, searchTerm) => {
    setIsLoading(true);
    const userCurrentPage = page;
    const search = searchTerm;
    IndependicareApi.getEventList(
      userCurrentPage,
      eventType,
      token,
      search
    ).then((res) => {
      setSearchQuery(searchTerm);
      setCurrentPage(page);

      const responseCode = STATUS_MSG[res?.data?.code];
      if (res?.code === STATUS_CODE.SUCCESS) {
        let eventList = res?.data?.eventList;
        setAddedCaregiverListCount(res?.data?.count);
        if (Array.isArray(eventList)) {
          eventList = eventList.map((event) => {
            if (event.date) {
              event.date = moment(event.date).format(DATE_FORMATES.DD_MM_YY);
            }
            if (event.time) {
              event.time = moment(event.time, "HH:mm:ss").format("hh:mm:ss A");
            }
            return event;
          });
        }
        setEventData(eventList);
        setIsLoading(false);
      } else if (
        res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        res?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
        setIsLoading(false);
      } else if (res?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(res?.data?.message, "error");
        setIsLoading(false);
      } else {
        Toster(t(responseCode), "error");
        setIsLoading(false);
      }
    });
  };

  const handleShowModalDelete = () => {
    setShowModalDelete(true);
    setEventIdToDelete(null);
  };

  useEffect(() => {
    getEventList(1, "");
  }, []);

  const deleteEvent = async (eventId) => {
    setEventIdToDelete(eventId);
    setShowModalDelete(true);
  };
  const deleteContent = async () => {
    setIsLoading(true);
    try {
      const response = await IndependicareApi.deleteEvent(
        eventIdToDelete,
        token
      );
      const responseCode = STATUS_MSG[response?.data?.code];
      if (response?.code === STATUS_CODE.SUCCESS) {
        getEventList(1, "");
        Toster(t("DAILY_EVENT_DELETED_SUCCESSFULLY"), "success");
      } else if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
      } else if (response?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(t(responseCode), "error");
      } else {
        Toster(t(responseCode), "error");
      }
    } catch (error) {
      console.error("Error deleting event:", error);
      Toster(t("ERROR_DELETING_EVENT"), "error");
    } finally {
      setIsLoading(false);
      setShowModalDelete(false);
    }
  };

  const filteredData = eventData.filter((item) => {
    const title = item.event_title ? item.event_title.toLowerCase() : "";
    return title.includes(searchQuery.toLowerCase());
  });

  const editEvent = (item) => {
    setEventItem(item);
    handleShowModal();
  };

  const activeInactiveFunction = (item) => {
    setSelectedStatus(item);
    handleShowModalStatus(item);
  };

  function truncateText(text, wordLimit) {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  }

  return (
    <div className="d-flex flex-column justify-content-between h-100 w-100">
      {isLoading && <Loader />}
      <div>
        <div className="">
          <Container>
            <Row className="align-items-center">
              <Col
                className="setSearchInputField"
                style={{ paddingBottom: "10px", paddingTop: "10px" }}
              >
                <CustomSearchField
                  setSearchQuery={(val) => {
                    setSearchQuery(val);
                    debouncedSearch(val);
                  }}
                />
              </Col>
              <Col>
                <div className="modelButtonPropwerty">
                  <Button
                    className="modalTextButton"
                    type="button"
                    onClick={handleShowModal}
                  >
                    {t("ADD_DAILY_EVENT")}
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div>
          <Container>
            <div className={styles?.eventTable}>
              <div className="setTableFromBottom">
                {eventData.length > 0 ? (
                  // <Table className="customTable" striped hover responsive>
                  //   <thead className="tableHeadProperty">
                  <Table className={styles.customTable} striped>
                    <thead className={styles.tableHeadProperty}>
                      <tr>
                        {/* <th>{t("DATE")}</th> */}
                        <th>{t("TIME")}</th>
                        <th>{t("EVENT_TYPE")}</th>
                        <th>{t("PERSON")}</th>
                        <th className={style.reminder}>{t("REMINDER")}</th>
                        <th>{t("STATUS")}</th>
                        <th
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {t("ACTION")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {eventData.map((item, index) => (
                        <tr key={index}>
                          {/* <td style={tdAlignClass} className="tdtext">
                          {item.date}
                        </td> */}
                          <td style={tdAlignClass} className="tdtext">
                            {item.time}
                          </td>
                          <td style={tdAlignClass} className="tdtext">
                            {item.event_title}
                          </td>
                          {/* <td style={tdAlignClass} className="tdtext">
                            {item.person_names}
                          </td> */}
                          <td
                            style={{
                              ...tdAlignClass,
                            }}
                            className="tdtextNotes tdtext"
                          >
                            {/* {item.notes} */}
                            <div className="tdContentWrapper">
                              {item.person_names}
                            </div>
                          </td>
                          {/* <td style={tdAlignClass} className="tdtext">
                          {item.reminder == "" || item.reminder == null
                            ? item.custom_reminder
                            : item.reminder}
                        </td> */}
                          <td
                            style={{
                              ...tdAlignClass,
                            }}
                            className="tdtextNotes tdtext"
                          >
                            <div className="tdContentWrapper">
                              {item.reminder === "" || item.reminder == null
                                ? item.custom_reminder
                                : item.reminder}
                            </div>
                          </td>
                          <td style={tdAlignClass} className="tdtext">
                            {item.is_active === 1 ? "Active" : "Inactive"}
                          </td>
                          <td>
                            <Dropdown className="setParentDropdown">
                              <Dropdown.Toggle
                                variant=""
                                id="dropdown-basic"
                                className="custom-dropdown-toggle p-0 border-0"
                              >
                                <MoreHorizIcon />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <div
                                  className="d-flex justify-content-around dropdownTextProperty"
                                  onClick={() => editEvent(item)}
                                >
                                  <Dropdown.Item>{t("EDIT")}</Dropdown.Item>
                                  <Image src={edit} className="dropdownIcons" />
                                </div>
                                <div
                                  className="d-flex justify-content-around dropdownTextProperty"
                                  onClick={() => deleteEvent(item.id)}
                                >
                                  <Dropdown.Item>{t("DELETE")}</Dropdown.Item>
                                  <DeleteOutlineIcon className="iconColor" />
                                </div>
                                <div
                                  className="d-flex justify-content-around dropdownTextProperty"
                                  onClick={() => {
                                    activeInactiveFunction(item);
                                  }}
                                >
                                  <Dropdown.Item>{`${
                                    item.is_active == 1 ? `Inactive` : `Active`
                                  }`}</Dropdown.Item>
                                </div>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <div className={style.noRecord}>
                    <p>{t("NO_RECORD_FOUND")}</p>
                  </div>
                )}
              </div>
            </div>
          </Container>
        </div>
      </div>

      <Pagination
        count={addedCaregiverListCount}
        pageLimit={pageLimit}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />

      <Modal show={showModalDelete} onHide={handleCloseModalDelete}>
        <Modal.Header className="d-flex justify-content-between">
          <p className={styles.Eventproperty}>{t("Alert")}</p>
          <CancelIcon
            className="closeIconProperty"
            onClick={handleCloseModalDelete}
          />
        </Modal.Header>
        <Modal.Body>
          <div className={styles.deleteClass}>
            <Image src={deleteImage} />
            <p>{t("ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_DAILY_EVENT")}</p>
          </div>
          <Button
            type="submit"
            className="AddSiteSubmitButton"
            onClick={deleteContent}
          >
            {t("OK")}
          </Button>
        </Modal.Body>
      </Modal>
      <AddDailyEvents
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        getEventList={() => {
          getEventList(1, "");
        }}
        pageNo={currentPage}
        eventItem={eventItem}
      />
      <DailyEventStatus
        showModal={showModalStatus}
        handleCloseModal={handleCloseModalStatus}
        selectedStatus={selectedStatus}
        getEventList={() => {
          getEventList(1, "");
        }}
      />
    </div>
  );
};
export default ShowDailyEvents;
