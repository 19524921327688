import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Dropdown from "react-bootstrap/Dropdown";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useTranslation } from "react-i18next";
import IndependicareApi from "../../../Helpers/Api";
import {
  Row,
  Col,
  Container,
  Table,
  Image,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import CancelIcon from "@mui/icons-material/Cancel";
import CustomSearchField from "../../../Components/CustomComponent/CustomSearchField/CustomSearchField";
import styles from "../../../Components/Settings/Level/Level.module.css";
import edit from "../../../Assests/Images/edit.png";
import { CustomSelectFields } from "../../CustomComponent/CustomInputFields/CustomInputField";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODE } from "../../../Utils/StatusCode";
import { STATUS_MSG } from "../../../Utils/StatusMessage";
import Toster from "../../../Toster/Toster";
import { clearUserState } from "../../../redux/slices/userSlice";
import { useNavigate } from "react-router-dom";
import deleteImage from "../../../Assests/Images/delete_image.png";
import Loader from "../../LoaderComponent/LoaderComponent";
import { ACTIVE_TYPE } from "../../../Utils/Constant";
import { CustomInputFields } from "../../CustomComponent/CustomInputFields/CustomInputField";
import LevelModal from "./LevelModal";

export const levelOption = Array.from({ length: 10 }, (_, i) => ({
  value: `value${i + 1}`,
  name: (i + 1).toString(),
}));

function Level() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const token = useSelector((state) => state.user.userToken);
  const [list, setList] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  // const handleShowModal = () => setShowModal(true);

  const [selectedLevelStatus, setSelectedLevelStatus] = useState();

  // const handleShowModal = () => {
  //   setIsEdit(false);
  //   reset();
  //   setShowModal(true);
  // };

  const [searchQuery, setSearchQuery] = useState("");

  const [selectLevel, setSelectLevel] = useState("");

  const [showlevel, setShowlevel] = useState(false);
  const handleCloseLevel = () => setShowlevel(false);
  const handleShowLevel = () => setShowlevel(true);

  const [showDelete, setShowdelete] = useState(false);
  const handleCloseDelete = () => setShowdelete(false);
  const handleShowDelete = () => setShowdelete(true);

  const [isEdit, setIsEdit] = useState(false);
  const [levelId, setLevelId] = useState();

  const [showLevelstatus, setShowLevel] = useState(false);
  const handleCloseStatusLevel = () => setShowLevel(false);
  const handleShowStatusLevel = () => setShowLevel(true);
  const [isShowModal, setIsShowModal] = useState({ show: false, id: "" });

  const handleShowModal = () => {
    setIsShowModal((prev) => ({ ...prev, show: true }));
  };

  const handleEditEvent = (id) => {
    setIsEdit(true);
    setIsShowModal(() => ({ id, show: true }));
  };

  const {
    register,
    handleSubmit,
    reset,
    formState,
    setValue,
    getValues,
    clearErrors,
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    handelGetData();
  }, []);

  const handelGetData = () => {
    setIsLoading(true);
    IndependicareApi.fetchLevel(token).then((response) => {
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("session expired"), "error");
        navigate("/signin");
      } else {
        setList(response?.data?.level);
        setIsLoading(false);
      }
    });
  };

  {
    /*=====Delete level======*/
  }

  const Leveldelete = (levelId) => {
    setIsLoading(true);
    IndependicareApi.deleteLevel(token, levelId).then((response) => {
      setIsLoading(false);
      const ResponseCode = STATUS_MSG[response?.data.code];

      if (response?.code == STATUS_CODE.SUCCESS) {
        Toster(t("LEVEL_DELETED_SUCCESSFULLY"), "success");
        handelGetData();
        setShowdelete(false);
      } else if (
        response?.code === STATUS_CODE.VALIDATIONS_ERROR ||
        response?.data?.code === STATUS_CODE.VALIDATIONS_ERROR
      ) {
        Toster(response?.code?.message, "error");
      } else {
        Toster(t(ResponseCode), "error");
      }
      setShowdelete(false);
    });
  };

  {
    /*=========Event status ===============*/
  }

  const Statuslevel = (eventId) => {
    setIsLoading(true);
    IndependicareApi.Levelstatus(token, eventId).then((response) => {
      handelGetData();
      setIsLoading(false);
      handleCloseStatusLevel();
    });
  };

  {
    /*====Search Level======*/
  }
  const filteredList = list.filter((item) =>
    item.level.toString().includes(searchQuery)
  );

  return (
    <>
      {isLoading && <Loader />}
      <div>
        <Container>
          <Row className="align-items-center">
            <Col className="setSearchInputField">
              <CustomSearchField setSearchQuery={setSearchQuery} />
            </Col>
            <Col>
              <div className="modelButtonPropwerty">
                <Button
                  className="modalTextButton"
                  type="button"
                  onClick={handleShowModal}
                >
                  {t("ADD_NEW")}
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <Container>
          <div className={styles.eventTable}>
            <Table className={styles.customTable} striped>
              <thead className={styles.tableHeadProperty}>
                <tr>
                  <th className={styles.EventalignLeft}>{t("LEVEL")}</th>
                  <th className={`${styles.eventAction1} ${styles.alignRight}`}>
                    {t("STATUS")}
                  </th>
                  <th className={`${styles.eventAction} ${styles.alignRight}`}>
                    {t("ACTION")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredList &&
                  filteredList.length > 0 &&
                  filteredList.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td
                          className={`${styles.alignLeft} ${styles.alignMiddleText}`}
                        >
                          {item.level}
                        </td>
                        <td className={styles.alignRight}>
                          {item.is_active == ACTIVE_TYPE.IS_ACTIVE
                            ? "Active"
                            : "Inactive"}
                        </td>
                        <td className={styles.alignRight}>
                          <Dropdown className={styles.setEventDropdown}>
                            <Dropdown.Toggle
                              variant=""
                              id="dropdown-basic"
                              className="custom-dropdown-toggle p-0 border-0"
                            >
                              <MoreHorizIcon />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <div
                                className="d-flex justify-content-around dropdownTextProperty"
                                onClick={() => {
                                  // handleShowLevel();
                                  // setIsEdit(true);
                                  // setLevelId(item.id);
                                  handleEditEvent(item.id);
                                }}
                              >
                                <Dropdown.Item>
                                  <span>{t("EDIT")}</span>
                                  <Image src={edit} className="dropdownIcons" />
                                </Dropdown.Item>
                              </div>

                              <div
                                className="d-flex justify-content-around dropdownTextProperty"
                                onClick={() => {
                                  handleShowDelete();
                                  setLevelId(item.id);
                                }}
                              >
                                <Dropdown.Item>
                                  <span>{t("DELETE")}</span>
                                  <DeleteOutlineIcon className="iconColor" />
                                </Dropdown.Item>
                              </div>
                              <div
                                className="d-flex justify-content-around dropdownTextProperty"
                                onClick={() => {
                                  handleShowStatusLevel();
                                  setLevelId(item.id);
                                  setSelectedLevelStatus(item.is_active);
                                }}
                              >
                                <Dropdown.Item>
                                  <span>
                                    {item.is_active == ACTIVE_TYPE.IS_ACTIVE
                                      ? "Inactive"
                                      : "Active"}
                                  </span>
                                  {/* <Image src={edit} className="dropdownIcons" /> */}
                                </Dropdown.Item>
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        </Container>
        <div className={styles.noRecord}>
          <p>
            {!isLoading && filteredList.length == 0 ? t("NO_RECORD_FOUND") : ""}
          </p>
        </div>
      </div>

      {/*=========Add-Level-Modal==============*/}

      {/* <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header className="d-flex justify-content-between">
          <p className={styles.Eventproperty}>{t("LEVEL")}</p>
          <CancelIcon
            className="closeIconProperty"
            onClick={handleCloseModal}
          />
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <CustomSelectFields
              PlaceHolder={t("SELECT_LEVEL")}
              RegisterName="level"
              register={register}
              options={levelOption}
              formState={formState}
              label={t("LEVEL")}
              value={selectLevel}
              onChange={(e) => {
                setValue("title", e.target.value.replace(/\s/g, ""), {
                  shouldValidate: true,
                });
              }}
            />
            <Button type="submit" className="AddSiteSubmitButton">
              {t("DONE")}
            </Button>
          </Form>
        </Modal.Body>
      </Modal> */}

      {/*=========Edit-Level-Modal==============*/}
      {/* <Modal show={showlevel} onHide={handleCloseLevel}>
        <Modal.Header className="d-flex justify-content-between">
          <p className={styles.Eventproperty}>{t("UPDATE")}</p>
          <CancelIcon
            className="closeIconProperty"
            onClick={handleCloseLevel}
          />
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(updateSubmit)}>
            <CustomSelectFields
              PlaceHolder={t("SELECT_LEVEL")}
              RegisterName="level"
              register={register}
              options={levelOption}
              formState={formState}
              label={t("LEVEL")}
              value={selectLevel}
              onChange={(e) => {
                setValue("level", e.target.value.replace(/\s/g, ""), {
                  shouldValidate: true,
                });
                clearErrors("level");
              }}
            />

            <Button type="submit" className="AddSiteSubmitButton">
              {t("DONE")}
            </Button>
          </Form>
        </Modal.Body>
      </Modal> */}

      {/*=========Level-delete-Modal==============*/}
      <Modal show={showDelete} onHide={handleCloseDelete}>
        <Modal.Header className="d-flex justify-content-between">
          <p className={styles.Eventproperty}>{t("Alert")}</p>
          <CancelIcon
            className="closeIconProperty"
            onClick={handleCloseDelete}
          />
        </Modal.Header>
        <Modal.Body>
          <div className={styles.deleteClass}>
            <Image src={deleteImage} />

            <p>{t("ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_LEVEL")}</p>
          </div>

          <Button
            type="submit"
            className="AddSiteSubmitButton"
            onClick={() => {
              Leveldelete(levelId);
            }}
          >
            {t("OK")}
          </Button>
        </Modal.Body>
      </Modal>

      {/*=========Level-Status-Modal==============*/}
      <Modal show={showLevelstatus} onHide={handleCloseStatusLevel}>
        <Modal.Header className="d-flex justify-content-between">
          <p className={styles.Eventproperty}>{t("Alert")}</p>
          <CancelIcon
            className="closeIconProperty"
            onClick={handleCloseStatusLevel}
          />
        </Modal.Header>
        <Modal.Body>
          <div className={styles.deleteClass}>
            {selectedLevelStatus === ACTIVE_TYPE.IS_ACTIVE ? (
              <p>{t("ARE_YOU_SURE_YOU_WANT_TO_INACTIVE_THIS_EVENT_TYPE")}</p>
            ) : (
              <p>{t("ARE_YOU_SURE_YOU_WANT_TO_ACTIVE_THIS_EVENT_TYPE")}</p>
            )}
          </div>

          <Button
            type="submit"
            className="AddSiteSubmitButton"
            onClick={() => {
              Statuslevel(levelId);
            }}
          >
            {t("OK")}
          </Button>
        </Modal.Body>
      </Modal>

      <LevelModal
        isShowModal={isShowModal}
        setIsShowModal={setIsShowModal}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        handelGetData={handelGetData}
      />
    </>
  );
}

export default Level;
