export const userData = [
  {
    name: "Adam Smith",
    Email: "adamsmith@gmail.com",
    contact_Number: "+91 283-4665-100",
    redear_Location: "Lorem Ipsum dollar dummy text",
  },
  {
    name: "Sushil Pal",
    Email: "adamsmith@gmail.com",
    contact_Number: "+91 283-4665-100",
    redear_Location: "Lorem Ipsum dollar dummy text",
  },
  {
    name: "Aditya Tiwari",
    Email: "adamsmith@gmail.com",
    contact_Number: "+91 283-4665-100",
    redear_Location: "Lorem Ipsum dollar dummy text",
  },
  {
    name: "Nihal verma",
    Email: "adamsmith@gmail.com",
    contact_Number: "+91 283-4665-100",
    redear_Location: "Lorem Ipsum dollar dummy text",
  },
  {
    name: "Adam Smith",
    Email: "adamsmith@gmail.com",
    contact_Number: "+91 283-4665-100",
    redear_Location: "Lorem Ipsum dollar dummy text",
  },
  {
    name: "Adam Smith",
    Email: "adamsmith@gmail.com",
    contact_Number: "+91 283-4665-100",
    redear_Location: "Lorem Ipsum dollar dummy text",
  },
  {
    name: "Sushil Pal",
    Email: "adamsmith@gmail.com",
    contact_Number: "+91 283-4665-100",
    redear_Location: "Lorem Ipsum dollar dummy text",
  },
  {
    name: "Aditya Tiwari",
    Email: "adamsmith@gmail.com",
    contact_Number: "+91 283-4665-100",
    redear_Location: "Lorem Ipsum dollar dummy text",
  },
  {
    name: "Nihal verma",
    Email: "adamsmith@gmail.com",
    contact_Number: "+91 283-4665-100",
    redear_Location: "Lorem Ipsum dollar dummy text",
  },
  {
    name: "Adam Smith",
    Email: "adamsmith@gmail.com",
    contact_Number: "+91 283-4665-100",
    redear_Location: "Lorem Ipsum dollar dummy text",
  },
  {
    name: "Nihal verma",
    Email: "adamsmith@gmail.com",
    contact_Number: "+91 283-4665-100",
    redear_Location: "Lorem Ipsum dollar dummy text",
  },
  {
    name: "Nihal verma",
    Email: "adamsmith@gmail.com",
    contact_Number: "+91 283-4665-100",
    redear_Location: "Lorem Ipsum dollar dummy text",
  },
  {
    name: "Nihal verma",
    Email: "adamsmith@gmail.com",
    contact_Number: "+91 283-4665-100",
    redear_Location: "Lorem Ipsum dollar dummy text",
  },
];

export const siteData = [
  {
    siteId: "1001",
    startDate: "10/05/24",
    independicare_Representative: "Lorem Ipsum dollar dummy text",
    rader_Location: "15th Street 47 W 18th St,New York, NY 10011, USA",
    Notes: "Lorem Ipsum",
  },
  {
    siteId: "1002",
    startDate: "12/05/24",
    independicare_Representative: "Lorem Ipsum dollar dummy text",
    rader_Location: "15th Street 47 W 18th St,New York, NY 10011, USA",
    Notes: "Lorem Ipsum",
  },
  {
    siteId: "1003",
    startDate: "15/05/24",
    independicare_Representative: "Lorem Ipsum dollar dummy text",
    rader_Location: "15th Street 47 W 18th St,New York, NY 10011, USA",
    Notes: "Lorem Ipsum",
  },
  {
    siteId: "1004",
    startDate: "15/05/24",
    independicare_Representative: "Lorem Ipsum dollar dummy text",
    rader_Location: "15th Street 47 W 18th St,New York, NY 10011, USA",
    Notes: "Lorem Ipsum",
  },
];

export const personData = [
  {
    Language_to_use: "English",
    TTS_command_string: "Command String Lorem Ipsum",
    Use_prompt_intro: "Lorem Ipsum dollar dummy text",
    Alert_with_TTS_Prompt: "Lorem Ipsum dollar dummy text",
    Alert_with_audio_wave_Prompt:
      "15th Street 47 W 18th St,New York, NY 10011, USA",
    Alert_tone_wave: "Lorem Ipsum",
    Notes: "Lorem Ipsum",
  },
  {
    Language_to_use: "English",
    TTS_command_string: "Command String Lorem Ipsum",
    Use_prompt_intro: "Lorem Ipsum dollar dummy text",
    Alert_with_TTS_Prompt: "Lorem Ipsum dollar dummy text",
    Alert_with_audio_wave_Prompt:
      "15th Street 47 W 18th St,New York, NY 10011, USA",
    Alert_tone_wave: "Lorem Ipsum",
    Notes: "Lorem Ipsum",
  },
  {
    Language_to_use: "English",
    TTS_command_string: "Command String Lorem Ipsum",
    Use_prompt_intro: "Lorem Ipsum dollar dummy text",
    Alert_with_TTS_Prompt: "Lorem Ipsum dollar dummy text",
    Alert_with_audio_wave_Prompt:
      "15th Street 47 W 18th St,New York, NY 10011, USA",
    Alert_tone_wave: "Lorem Ipsum",
    Notes: "Lorem Ipsum",
  },
];

export const eventData = [
  {
    Date: "13/12/24",
    Time: "12:13:00 AM",
    event_Type: "Lorem Ipsum",
    Reminder: "Reminder",
  },
  {
    Date: "13/12/24",
    Time: "12:13:00 AM",
    event_Type: "Lorem Ipsum",
    Reminder: "Reminder",
  },
];

export const dailyEventData = [
  { Time: "12:13:00 AM", event_Type: "Lorem Ipsum", Reminder: "Reminder" },
  { Time: "12:13:00 AM", event_Type: "Lorem Ipsum", Reminder: "Reminder" },
];

export const permission = [
  { permission: "Lorem Ipsum dummy Text" },
  { permission: "Lorem Ipsum dummy Text" },
];

export const messageLevel = [
  { time_Message: "Time Message", level: "Level 4" },
  { time_Message: "Time Message", level: "Level 1" },
];

export const levelOption = [];

for (let i = 0; i < 10; i++) {
  levelOption.push({
    value: `value${i + 1}`,
    name: (i + 1).toString(),
  });
}
