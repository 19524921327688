import React, { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CancelIcon from "@mui/icons-material/Cancel";
import IndependicareApi from "../../Helpers/Api";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import Toster from "../../Toster/Toster";
import { STATUS_CODE } from "../../Utils/StatusCode";
import { STATUS_MSG } from "../../Utils/StatusMessage";
import Loader from "../LoaderComponent/LoaderComponent";
import styles from "./EventStatus.module.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearUserState } from "../../redux/slices/userSlice";

function EventStatus({
  showModal,
  handleCloseModal,
  selectedStatus,
  getEventList,
  pageNo,
}) {
  const {
    register,
    handleSubmit,
    reset,
    formState,
    setValue,
    errors,
    control,
    className,
  } = useForm({
    mode: "onChange",
  });
  const { t } = useTranslation();

  const token = useSelector((state) => state.user.userToken);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStatusActive, setSelectedStatusActive] = useState("");
  const [isActive, setIsActive] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedStatus) {
      setSelectedStatusActive(selectedStatus.id);
      setIsActive(selectedStatus.is_active);
    }
  }, selectedStatus);

  const onSubmit = async (data) => {
    const formData = new FormData();

    const newStatus = isActive === 1 ? 0 : 1;
    formData.append("is_active", newStatus);
    try {
      setIsLoading(true);
      const res = await IndependicareApi.eventStatusUpdate(
        formData,
        selectedStatusActive,
        token
      );
      const responseCode = STATUS_MSG[res?.data?.code];
      if (res?.code === STATUS_CODE.SUCCESS) {
        Toster(t("EVENT_STATUS_UPDATED_SUCCESSFULLY"), "success");
        getEventList(pageNo);
      } else if (
        res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        res?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
      } else if (res?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(res?.data?.message, "error");
      } else {
        Toster(t(responseCode), "error");
      }
    } catch (error) {
      Toster(t("ERROR_UPDATING_EVENT_STATUS"), "error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header className="d-flex justify-content-between">
          <Modal.Title className={`setModelTitleProperty ${styles.modelTitle}`}>
            {t("EVENT_STATUS")}
          </Modal.Title>
          <CancelIcon
            className="closeIconProperty"
            onClick={handleCloseModal}
          />
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            {isLoading && <Loader />}
            <h4 className={styles.activeDeactiveProperty}>{`${t(
              "ARE_YOU_SURE"
            )},${t("YOU_WANT")} ${isActive == 1 ? "Inactive" : "Active"} ${t(
              "THE_EVENT_STATUS"
            )}`}</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="AddSiteSubmitButton"
              type="submit"
              onClick={handleCloseModal}
            >
              {t("UPDATE_EVENT_STATUS")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}
export default EventStatus;
