import React, { useEffect, useState } from "react";
import styles from "../StakeholderDetail/StakeholderDetail.module.css";
import { Container } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MdArrowBack } from "react-icons/md";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Site from "../StakeholderDetail/Site";
import Person from "../StakeholderDetail/Person";
import IndependicareApi from "../../Helpers/Api";
import { STATUS_MSG } from "../../Utils/StatusMessage";
import { STATUS_CODE } from "../../Utils/StatusCode";
import { clearUserState } from "../../redux/slices/userSlice";
import Toster from "../../Toster/Toster";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../LoaderComponent/LoaderComponent";
import Tag from "../StakeholderDetail/Permission";
import Devices from "../StakeholderDetail/MessageLevel";

function StakeholderDetail() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.userToken);

  const [isLoading, setIsLoading] = useState(false);

  const [stakeholderDetails, setStakholderDetails] = useState();
  const [siteList, setSitList] = useState();
  const [personList, setPersonList] = useState();
  const [deviceList, setDeviceList] = useState();
  const [tagList, setTagList] = useState();

  const [siteListCount, setSitListCount] = useState(1);
  const [personListCount, setPersonListCount] = useState(1);
  const [deviceListCount, setDeviceListCount] = useState(1);
  const [tagListCount, setTagListCount] = useState(1);

  const location = useLocation();
  const state = location?.state || {};

  useEffect(() => {
    const payLoads = {
      id: state?.itemId,
      page_no: 1,
    };
    getStakeholderDetails(payLoads);
  }, []);

  const getStakeholderDetails = (payLoads) => {
    setIsLoading(true);
    IndependicareApi.getStakeholderDetails(token, payLoads?.id, payLoads).then(
      (response) => {
        const responseCode = STATUS_MSG[response?.data?.code];
        setIsLoading(false);
        if (response?.code === STATUS_CODE.SUCCESS) {
          setSitList(response?.data?.siteList);
          setSitListCount(response?.data?.siteCount);
          setPersonList(response?.data?.personList);
          setPersonListCount(response?.data?.personCount);
          setTagList(response?.data?.tagList);
          setTagListCount(response?.data?.tagCount);
          setDeviceList(response?.data?.deviceList);
          setDeviceListCount(response?.data?.deviceCount);
          setStakholderDetails(response?.data?.StakeholderDetail);
        } else if (
          response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODE.INACTIVE_USER
        ) {
          dispatch(clearUserState());
          Toster(t("SESSION_EXPIRED"), "error");
          navigate("/signin");
        } else if (response?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
          Toster(t(responseCode), "error");
        } else {
          Toster(t(responseCode), "error");
        }
      }
    );
  };
  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.tbl}>
        <div className={styles.backArrowSec}>
          <Link to="/stakeholder">
            <div className={styles.backArrow}>
              <MdArrowBack />
              <span>{t("STAKEHOLDER_DETAILS")}</span>
            </div>
          </Link>
        </div>

        <Container>
          <div className={styles.infoSection}>
            <h3>{t("BASIC_INFO")}</h3>
            <div className={`${styles.mainInfo_sec} ${styles.borderTop}`}>
              <div className={styles.userInfo}>
                <h4>{t("NAME")}</h4>
                <p>{stakeholderDetails?.name}</p>
              </div>

              <div className={styles.userInfo1}>
                <h4>{t("EMAIL")}</h4>
                <p>{stakeholderDetails?.email}</p>
              </div>
            </div>
            <div className={`${styles.mainInfo_sec} ${styles.borderBottom}`}>
              <div className={styles.userInfo}>
                <h4>{t("CONTACT_NUMBER")}</h4>
                <p>
                  {stakeholderDetails?.dial_code +
                    " " +
                    stakeholderDetails?.phone_number}
                </p>
              </div>

              <div className={styles.userInfo1}>
                <h4>{t("LOCATION")}</h4>
                <p>{stakeholderDetails?.street_address}</p>
              </div>
            </div>
          </div>

          {stakeholderDetails ? (
            <div className={styles.mtb20}>
              <Tabs
                defaultActiveKey="home"
                id="justify-tab-example"
                className="mb-3"
                justify
              >
                <Tab eventKey="home" title="Site">
                  <Site
                    siteList={siteList}
                    count={siteListCount}
                    id={state?.itemId}
                  />
                </Tab>
                <Tab eventKey="profile" title="Person">
                  <Person
                    personList={personList}
                    count={personListCount}
                    id={state?.itemId}
                  />
                </Tab>
                <Tab eventKey="permissinon" title="Tag">
                  <Tag
                    tagList={tagList}
                    count={tagListCount}
                    id={state?.itemId}
                  />
                </Tab>
                <Tab eventKey="messagelevel" title="Device">
                  <Devices
                    deviceList={deviceList}
                    count={deviceListCount}
                    id={state?.itemId}
                  />
                </Tab>
              </Tabs>
            </div>
          ) : null}
        </Container>
      </div>
    </>
  );
}
export default StakeholderDetail;
