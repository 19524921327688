import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import style from "../CareGiver/CareGiver.module.css";
import Loader from "../LoaderComponent/LoaderComponent";
import add_icon from "../../Assests/Images/icon_add.png";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Pagination from "../CustomComponent/CustomPagination/Pagination";
import {
  Row,
  Col,
  Container,
  Table,
  Image,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import IndependicareApi from "../../Helpers/Api";
import { STATUS_MSG } from "../../Utils/StatusMessage";
import { STATUS_CODE } from "../../Utils/StatusCode";
import Toster from "../../Toster/Toster";
import { clearUserState } from "../../redux/slices/userSlice";
import styles from "../../Components/Settings/Level/Level.module.css";
import CancelIcon from "@mui/icons-material/Cancel";
import deleteImage from "../../Assests/Images/delete_image.png";
function AssignPerson() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const token = useSelector((state) => state.user.userToken);
  const [pageNo, setPageNo] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const pageLimit = 10;
  const [addedAssignPersonListCount, setAddedAssignPersonListCount] =
    useState(0);
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    // getAddedCareGiverList(pageNumber, searchQuery);
  };
  const [assignPersonList, setAssignPersonList] = useState([]);
  const tdAlignClass = { flex: 1, alignContent: "center" };
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const handleShowDeleteModal = () => setShowDeleteModal(true);
  const [careGiverDeleteId, setCareGiverDelete] = useState();

  const [showDelete, setShowdelete] = useState(false);
  const handleCloseDelete = () => setShowdelete(false);
  const handleShowDelete = () => setShowdelete(true);

  useEffect(() => {
    getAssignPersonList(currentPage, "");
  }, []);
  const getAssignPersonList = async (pageNo, searchTerm) => {
    try {
      setIsLoading(true);
      const res = await IndependicareApi.getAssignPersonList(
        pageNo,
        searchTerm,
        token
      );
      setIsLoading(false);
      const responseCode = STATUS_MSG[res?.data?.code];

      if (res?.code === STATUS_CODE.SUCCESS) {
        const assignPersonList = res.data.assignedSeniors;

        setAssignPersonList(assignPersonList);
        setAddedAssignPersonListCount(res.data.count);
      } else if (
        res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        res?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
      } else if (res?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(t(responseCode), "error");
      } else {
        Toster(t(responseCode), "error");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching device list:", error);
    }
  };

  return (
    <div className="d-flex flex-column justify-content-between w-100 mt-3">
      <div>
        {isLoading && <Loader />}
        <Container>
          <div className="setTableFromBottom">
            {assignPersonList && assignPersonList.length > 0 ? (
              <Table className="customTable" striped hover responsive>
                <thead className="tableHeadProperty">
                  <tr>
                    <th>{t("NAME")}</th>
                    <th>{t("RELATION")}</th>
                    <th>{t("LEVEL")}</th>
                    <th className={style.permission}>{t("PERMISSION")}</th>
                    {/* <th>{t("ACTION")}</th> */}
                  </tr>
                </thead>
                {assignPersonList && assignPersonList.length > 0 ? (
                  <tbody>
                    {assignPersonList.map((item, index) => (
                      <tr key={index}>
                        <td
                          // onClick={() => {
                          //   navigate("/caregiverdetails", {
                          //     state: { id: item.id },
                          //   });
                          // }}
                          style={tdAlignClass}
                          className="tdtext"
                        >
                          {item?.person_name}
                        </td>
                        <td style={tdAlignClass} className="tdtext">
                          {item.relation}
                        </td>
                        <td style={tdAlignClass} className="tdtext">
                          {item.level}
                        </td>
                        <td style={tdAlignClass} className="tdtext">
                          {item.module_names}
                        </td>
                        {/* <td>
                          <Dropdown className="setParentDropdown">
                            <Dropdown.Toggle
                              variant=""
                              id="dropdown-basic"
                              className="custom-dropdown-toggle p-0 border-0"
                            >
                              <MoreHorizIcon />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <div
                                onClick={() => {
                                  setCareGiverDelete(item.id);
                                  // handleShowDelete();
                                }}
                                className="d-flex justify-content-around dropdownTextProperty"
                              >
                                <Dropdown.Item>{t("DELETE")}</Dropdown.Item>
                                <DeleteOutlineIcon className="iconColor" />
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <div className={style.noRecord}>
                    <p>{t("NO_RECORD_FOUND")}</p>
                  </div>
                )}
              </Table>
            ) : (
              <div className={style.noRecord}>
                <p>{t("NO_RECORD_FOUND")}</p>
              </div>
            )}
          </div>
        </Container>
      </div>
      <Pagination
        count={addedAssignPersonListCount}
        pageLimit={pageLimit}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />

      {/*=========AssignPerson-delete-Modal==============*/}
      <Modal show={showDelete} onHide={handleCloseDelete}>
        <Modal.Header className="d-flex justify-content-between">
          <p className={styles.Eventproperty}>{t("Alert")}</p>
          <CancelIcon
            className="closeIconProperty"
            onClick={handleCloseDelete}
          />
        </Modal.Header>
        <Modal.Body>
          <div className={styles.deleteClass}>
            <Image src={deleteImage} />

            <p>{t("Are you sure you want to delete this assign person")}</p>
          </div>

          <Button
            type="submit"
            className="AddSiteSubmitButton"
            onClick={() => {
              // Leveldelete(levelId);
            }}
          >
            {t("OK")}
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default AssignPerson;
