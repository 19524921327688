import React, { useState, useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import style from "./UpdateProfile.module.css";
import { Container, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CustomInputFields } from "../../Components/CustomComponent/CustomInputFields/CustomInputField";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { CustomSelectFields } from "../../Components/CustomComponent/CustomInputFields/CustomInputField";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CustomButton from "../CustomComponent/CustomButton/CustomButton";
import IndependicareApi from "../../Helpers/Api";
import { STATUS_MSG } from "../../Utils/StatusMessage";
import { STATUS_CODE } from "../../Utils/StatusCode";
import Toster from "../../Toster/Toster";
import { ValidationSchema } from "../../Utils/ValidationSchema";
import { useDispatch, useSelector } from "react-redux";
import { getProfile, setUserType } from "../../redux/slices/userSlice";
import { timeZoneCountryMapping } from "../../Utils/MockData";
import moment from "moment-timezone";
import { CustomSelectFieldsSeachState } from "../CustomComponent/CustomInputFields/CustomInputField";
import { useLocation } from "react-router-dom";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { removeExtraPlusSign } from "../../Utils/Common";
import { clearUserState } from "../../redux/slices/userSlice";
import { isValidPhoneNumber } from "libphonenumber-js";

const UpdateProfile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user.currentUser);
  const location = useLocation();
  const formData = location.state.profileData;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [dialCode, setDialCode] = useState();
  const [contactNumber, setContactNumber] = useState("");
  const [countryData, setCountryData] = useState([]);
  const [countryCode, setCountryCode] = useState("");
  const [countryID, setCountryID] = useState("");
  const [sourceID, setSourceID] = useState("");
  const [sourceData, setSourceData] = useState([]);
  const [statesData, setStatesData] = useState([]);
  const [statesOption, setStatesOption] = useState([]);
  const [updateID, setUpdateID] = useState("");
  const [statesID, setStatesID] = useState("");
  const [ip, setIP] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [profileID, setProfileID] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState,
    formState: { errors },
    setValue,
  } = useForm({
    mode: "onChange",
  });

  const usertype = useSelector((state) => state.user.userType);
  const token = useSelector((state) => state.user.userToken);

  const handleCountryData = () => {
    const countrydata = IndependicareApi.countryList();
    countrydata.then((res) => {
      const responseCode = STATUS_MSG[res?.data?.code];
      if (res?.code === STATUS_CODE.SUCCESS) {
        setCountryData(res?.data?.country_list);
      } else if (
        res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        res?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
      } else if (res?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(res?.data?.message, "error");
      } else {
        Toster(t(responseCode), "error");
      }
    });
  };

  useEffect(() => {
    handleCountryData();
  }, []);

  useEffect(() => {
    const options = [];
    if (statesData.length > 0) {
      statesData.map((state) => {
        options.push({ value: state.id, label: state.name });
      });
      setStatesOption(options);
    }
  }, [statesData]);

  const handleSelectChangeCountry = (e) => {
    setCountryID(e.target.value);
  };

  const handleSelectChangeState = (e) => {
    setStatesID(e.value);
  };

  useEffect(() => {
    if (formData) {
      setProfileID(formData.id);
      setValue("fullName", formData.name);
      setValue("city", formData.city);
      setValue("zipCode", formData.zipcode);
      setValue("address", formData.street_address);
      setValue("address2", formData.street_address_line2);
      setValue("selectedImageFile", formData.profile_img);
      setImageUrl(formData.profile_img);

      if (formData && usertype === "caregiver") {
        setValue("sourceID", formData.source_id);
        setSourceID(formData.source_id);
      }

      const contact = `${formData.dial_code} ${formData.phone_number}`;
      setCountryCode(formData.country_code);
      setDialCode(formData.dial_code);
      setContactNumber(formData.phone_number);

      setPhoneNumber(contact);
      setValue("contact", formData.phone_number);

      setValue("country", formData.country_id);
      setCountryCode(formData.country_code);
      setCountryID(formData.country_id);
      setStatesID(formData.state_id);
      handleStateData(formData.country_id);
    }
  }, [formData, countryData, sourceData]);

  const handleStateData = (countryID) => {
    const statedata = IndependicareApi.stateList(countryID);
    statedata.then((res) => {
      const responseCode = STATUS_MSG[res?.data?.code];
      if (res?.code === STATUS_CODE.SUCCESS) {
        const sortedStates = res?.data?.state_list.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setStatesData(sortedStates);
        setValue("state", formData?.state_id);
      } else if (
        res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        res?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
      } else if (res?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(res?.data?.message, "error");
      } else {
        Toster(t(responseCode), "error");
      }
    });
  };

  useEffect(() => {
    if (countryID) {
      handleStateData(countryID);
    }
  }, [countryID]);

  const handlesourceID = () => {
    const sourcedata = IndependicareApi.sourceID();
    sourcedata.then((res) => {
      const responseCode = STATUS_MSG[res?.data?.code];
      if (res?.code === STATUS_CODE.SUCCESS) {
        setSourceData(res?.data?.caregiver_source_list);
      } else if (
        res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        res?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
      } else if (res?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(res?.data?.message, "error");
      } else {
        Toster(t(responseCode), "error");
      }
    });
  };

  useEffect(() => {
    handlesourceID();
  }, []);

  const handleChangeSourceId = (e) => {
    setSourceID(e.target.value);
  };
  useEffect(() => {
    if (localStorage.getItem("usertype")) {
      localStorage.setItem("usertype", currentUser.user_type);
    }
  }, []);

  const isContactValid = () => {
    const isValid = isValidPhoneNumber(
      `${removeExtraPlusSign(`+${dialCode}`)}${contactNumber}`
    );
    return isValid;
  };

  const onSubmit = (data) => {
    if (!isContactValid()) {
      return;
    }
    const formData = new FormData();
    formData.append("full_name", data.fullName);
    formData.append("city", data.city);
    formData.append("zip", data.zipCode);
    formData.append("contact_number", contactNumber);
    formData.append("country_code", countryCode);
    formData.append("street_address", data.address);
    {
      data.address2 && formData.append("street_address_line2", data.address2);
    }
    formData.append("country_id", countryID);
    formData.append("state_id", statesID);
    formData.append("dial_code", removeExtraPlusSign(dialCode));
    formData.append("user_type", usertype);
    if (usertype === "caregiver") {
      formData.append("source_id", sourceID);
    }
    if (selectedImageFile) {
      formData.append("profile_img", selectedImageFile);
    }
    if (!contactNumber || contactNumber?.length > 4) {
      if (profileID) {
        setIsLoading(true);
        IndependicareApi.updateProfile(formData, token)
          .then((res) => {
            setIsLoading(false);
            const ResponseCode = STATUS_MSG[res?.data?.code];
            if (res?.code === STATUS_CODE.SUCCESS) {
              handleGetProfile();
              Toster(t("PROFILE_UPDATED_SUCCESSFULLY"), "success");
              navigate("/profile");
            } else if (
              res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
              res?.data?.code === STATUS_CODE.INACTIVE_USER
            ) {
              dispatch(clearUserState());
              Toster(t("SESSION_EXPIRED"), "error");
              navigate("/signin");
            } else if (res?.code === STATUS_CODE.VALIDATIONS_ERROR) {
              Toster(t(ResponseCode), "error");
            } else {
              Toster(t(ResponseCode), "error");
            }
          })
          .catch((error) => {
            Toster(error, "error");
          });
      }
    }
  };

  useEffect(() => {
    const timeZone = moment.tz.guess();
    const countryCodeInfo = timeZoneCountryMapping[timeZone];
    setDialCode(countryCodeInfo?.code);
    setCountryCode(countryCodeInfo?.country);
  }, []);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleIconClick = () => {
    document.getElementById("fileInput").click();
  };

  const handleChangeProfile = () => {
    navigate("/profile");
  };

  const handleGetProfile = () => {
    setIsLoading(true);
    dispatch(getProfile(token)).then((res) => {
      setIsLoading(false);
      var response = res.payload;
      const ResponseCode = STATUS_MSG[response?.data?.code];
      if (response?.code === STATUS_CODE.SUCCESS) {
      } else if (
        res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        res?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
      } else if (response?.data?.code == STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(response?.data?.message, "error");
      } else {
        Toster(t(ResponseCode), "error");
      }
    });
  };

  return (
    <div>
      <Container>
        <div className={style.editProfile}>
          <h4
            className={style.headingTextProperty}
            onClick={handleChangeProfile}
          >
            <ArrowBackIcon className={style.setIconProperty} />
            {t("EDIT_PROFILE")}
          </h4>
          <div className={style.formContainer}>
            <div className={style.formInputFields}>
              <div className={style.registerFormsField}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <h4 className={style.headingTextProfile}>
                    {t("PROFILE_PICTURE")}
                  </h4>
                  <div className={style.uploadimageContainer}>
                    <div className={style.profileContainer}>
                      <Image
                        src={imageUrl || "default-profile-img-url"}
                        roundedCircle
                        className={style.imageUrlProperty}
                      />
                      <input
                        type="file"
                        id="fileInput"
                        style={{ display: "none" }}
                        accept="image/*"
                        onChange={handleImageUpload}
                      />
                      <div
                        className={style.setButton}
                        onClick={handleIconClick}
                      >
                        <CameraAltIcon className={style.uploadButton} />
                      </div>
                    </div>
                  </div>

                  <CustomInputFields
                    type="text"
                    PlaceHolder={t("ENTER_FULL_NAME")}
                    RegisterName="fullName"
                    register={register}
                    formState={formState}
                    label={t("FULL_NAME")}
                  />

                  <Form.Group className="formPadding" controlId="phoneInput">
                    <Form.Label>{t("CONTACT_NUMBER")}</Form.Label>

                    <PhoneInput
                      defaultValue={phoneNumber}
                      {...register("contact", ValidationSchema.contact)}
                      className={`${style.phoneInputFieldProperty} ${
                        errors.contact ? style.errorBorder : ""
                      }`}
                      countryCodeEditable={false}
                      value={`${dialCode} ${contactNumber}`}
                      onChange={(value, country) => {
                        let dialCode = country.dialCode;
                        let phone = value.slice(dialCode.length, value.length);
                        setDialCode(dialCode);
                        setContactNumber(phone);
                      }}
                      onlyCountries={["in", "us"]}
                    />

                    {!contactNumber && (
                      <p className={`${style.errorMessage} text-danger`}>
                        {t(errors.contact?.message)}
                      </p>
                    )}
                    {!isValidPhoneNumber(
                      `${removeExtraPlusSign(`+${dialCode}`)}${contactNumber}`
                    ) &&
                      formState.isSubmitted &&
                      contactNumber && (
                        <p className={`${style.errorMessage} text-danger`}>
                          Enter valid phone number
                        </p>
                      )}
                    {/* {contactNumber?.length > 0 &&
                      contactNumber?.length <= 4 && (
                        <p className={`${style.errorMessage} text-danger`}>
                          {t("CONTACT_NO_MIN_5_DIGIT")}
                        </p>
                      )} */}
                  </Form.Group>
                  <CustomInputFields
                    type="text"
                    PlaceHolder={t("ENTER_THE_ADDRESS")}
                    RegisterName="address"
                    register={register}
                    formState={formState}
                    label={t("STREET_ADDRESS")}
                  />
                  <CustomInputFields
                    type="text"
                    PlaceHolder={t("ENTER_THE_ADDRESS_2")}
                    RegisterName="address2"
                    register={register}
                    formState={formState}
                    label={t("STREET_ADDRESS_2")}
                    setValue={setValue}
                  />
                  <CustomSelectFields
                    PlaceHolder={t("SELECT_COUNTRY")}
                    RegisterName="country"
                    register={register}
                    options={countryData}
                    formState={formState}
                    label={t("COUNTRY")}
                    onChangeHandler={handleSelectChangeCountry}
                  />
                  <CustomSelectFieldsSeachState
                    PlaceHolder={t("SELECT_STATE")}
                    RegisterName="state"
                    register={{ control }}
                    options={statesOption}
                    formState={formState}
                    label={t("STATE")}
                    onChangeHandler={handleSelectChangeState}
                  />
                  <CustomInputFields
                    type="text"
                    PlaceHolder={t("ENTER_CITY")}
                    RegisterName="city"
                    register={register}
                    formState={formState}
                    label={t("CITY")}
                  />

                  <CustomInputFields
                    type="text"
                    PlaceHolder={t("ENTER_ZIP_POSTAL_CODE")}
                    RegisterName="zipCode"
                    register={register}
                    formState={formState}
                    label={t("ZIP_POSTAL_CODE")}
                  />
                  {usertype === "caregiver" && (
                    <CustomSelectFields
                      PlaceHolder={t("ENTER_SOURCE_ID")}
                      RegisterName="sourceID"
                      register={register}
                      formState={formState}
                      label={t("SOURCE_ID")}
                      options={sourceData}
                      onChangeHandler={(e) => handleChangeSourceId(e)}
                    />
                  )}
                  <div className={style.buttonContainer}>
                    <CustomButton
                      type="submit"
                      value={t("UPDATE_PROFILE")}
                      className={style.buttonProperty}
                    />
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default UpdateProfile;
