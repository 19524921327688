import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import CustomSearchField from "../CustomComponent/CustomSearchField/CustomSearchField";
import { Col, ListGroup } from "react-bootstrap";
import iconTick from "../../Assests/Images/icon_tickgreen.png";
import styles from "./CareGiver.module.css";

function AddCareGiver({
  careGiverList,
  onSelectCareGiver,
  setCareGiverSearch,
  searchValue,
  debouncedSearchForCaregiver,
  listInnerRef,
  onScroll,
}) {
  const { t } = useTranslation();

  return (
    <div>
      <Col className="setSearchInputField">
        <div className="d-flex justify-content-center">
          <CustomSearchField
            value={searchValue}
            setSearchQuery={(val) => {
              setCareGiverSearch(val);
              debouncedSearchForCaregiver(val);
            }}
          />
        </div>

        <div
          className={styles.personListContainer}
          ref={listInnerRef}
          onScroll={onScroll}
          style={{
            marginTop: 10,
            maxHeight: "55vh",
            overflowY: "auto",
          }}
        >
          <ListGroup>
            {careGiverList && careGiverList.length > 0 ? (
              careGiverList.map((caregiver, index) => (
                <ListGroup.Item
                  key={index}
                  onClick={() => {
                    onSelectCareGiver(index);
                  }}
                  className="d-flex justify-content-between align-items-center"
                  style={{
                    cursor: "pointer",
                    border: "none",
                    borderBottom:
                      index === careGiverList.length - 1
                        ? "0px solid #EFEFEF"
                        : "1px solid #EFEFEF",
                    borderRadius: "0",
                    paddingTop: 15,
                    paddingBottom: 15,
                  }}
                >
                  <div>
                    <div
                      style={{
                        color: caregiver.isSelected ? "#5DA128" : "#202020",
                        fontSize: 16,
                        fontWeight: 500,
                      }}
                    >
                      {caregiver.name}
                    </div>
                    <div
                      style={{
                        color: "#626262",
                        fontSize: 12,
                        fontWeight: 500,
                      }}
                    >
                      {caregiver.email}
                    </div>
                  </div>
                  {caregiver.isSelected && (
                    <img
                      src={iconTick}
                      alt="Selected"
                      style={{ width: "24px", height: "24px" }}
                    />
                  )}
                </ListGroup.Item>
              ))
            ) : (
              <div className="d-flex justify-content-center p-2">
                <p>{t("NO_RECORD_FOUND")}</p>
              </div>
            )}
          </ListGroup>
        </div>
      </Col>
    </div>
  );
}
export default AddCareGiver;
