export const STATUS_CODE = {
  HEADER_IS_MISSING: 100,
  DEVICE_TYPE_NOT_ALLOWED: 101,
  INVALID_API_KEY: 102,
  A_TOKEN_IS_REQUIRED_FOR_AUTHENTICATION: 103,
  INVALID_TOKEN: 104,
  VALIDATIONS_ERROR: 105,
  INVALID_CODE: 106,
  INVALID_ID: 107,
  ACCESS_DENIED_TO_USER_ENTER_VALID_TOKEN: 108,
  INVALID_USER: 109,
  PROFILE_IMAGE_IS_NOT_FOUND: 110,
  INVALID_LOGIN_CREDENTIALS: 111,
  ACCOUNT_NOT_FOUND: 112,
  INACTIVE_USER: 113,
  USER_ACCOUNT_IS_DELETE: 114,
  OLD_PASSWORD_IS_NOT_MATCH: 115,
  INVALID_LEVEL_ID: 116,
  EMAIL_EXIST: 117,
  PHONE_NUMBER_EXIST: 118,
  DEVICE_ID_ALREADY_EXIST: 119,
  RECORD_NOT_FOUND: 120,
  SERIAL_NUMBER_ALREADY_EXIST: 121,
  INVALID_FILE_EXTENSION: 122,
  EMAIL_SEND_FAIL: 123,
  INVALID_USER_TYPE: 124,
  OTP_NOT_MATCH: 125,
  INVALID_SOURCE_ID: 126,
  TOKEN_EXPIRE: 127,
  RECORD_EXIST: 128,
  INVALID_FILE_EXTENTION: 129,
  SIZE_EXCEED_LIMIT: 130,
  INVALID_EVENT_TYPE_ID: 131,
  INVALID_REMINDER_ID: 132,
  INVALID_LEVEL_ID: 133,
  INVALID_PERSON_ID: 134,
  INVALID_LOCATION_TYPE_ID: 135,
  INVALID_SUB_LOCATION_TYPE_ID: 136,
  UNIQUE_ID_EXIST: 137,
  IT_CAN_NOT_BE_DELETED_ALREADY_USED: 138,
  INVALID_ADDED_CAREGIVER_ID: 139,
  INVALID_RELATION_ID: 140,
  INVALID_PERMISSION_ID: 141,
  INVALID_DEVICE_ID: 142,
  INVALID_TAG_ID: 143,
  ASSIGNED_SENIOR_ID_CAN_NOT_BE_DELETED: 144,
  INVALID_CONTENT_PAGE_NAME: 145,
  THE_TIME_MUST_BE_CURRENT_OR_FUTURE_TIME: 146,
  PERSON_NAME_EXIST: 147,
  INVALID_ASSIGN_ID: 148,
  SUCCESS: 200,
};
