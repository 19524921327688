import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Image,
  Row,
  Table,
} from "react-bootstrap";
import Loader from "../../LoaderComponent/LoaderComponent";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import IndependicareApi from "../../../Helpers/Api";
import { STATUS_CODE } from "../../../Utils/StatusCode";
import { userLogoutClear } from "../../../redux/slices/userSlice";
import Toster from "../../../Toster/Toster";
import styles from "./LocationType.module.css";
import debounce from "debounce";
import { ACTIVE_TYPE } from "../../../Utils/Constant";
import CustomSearchField from "../../CustomComponent/CustomSearchField/CustomSearchField";
import AddLocationTypeModel from "./AddLocationTypeModel";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Confirm from "./Confirm";
import { STATUS_MSG } from "../../../Utils/StatusMessage";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import edit from "../../../Assests/Images/edit.png";

export default function LocationType() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userToken = useSelector((state) => state.user.userToken);
  const { t } = useTranslation();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const [location, setLocation] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [locationId, setLocationId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  // chnageStaus popup funtion
  const [statusshow, setStatusShow] = useState(false);
  const handleStatusClose = () => setStatusShow(false);
  const [locationStatus, setLocationStatus] = useState(false);
  // delete popup funtion
  const [deleteshow, setDeleteShow] = useState(false);
  const handleDeleteClose = () => setDeleteShow(false);
  // serch bar states
  const [searchTerm, setSearchTerm] = useState("");
  const [isShowConfirm, setIsShowConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);

  // get apicall
  const handleGetLocationData = (searchTerm) => {
    setIsLoading(true);
    IndependicareApi.fetchLocationType(userToken, searchTerm).then(
      (response) => {
        setIsLoading(false);
        if (
          response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODE.INACTIVE_USER
        ) {
          dispatch(userLogoutClear());
          Toster(t("SESSION_EXPIRED"), "error");
          navigate("/");
        } else {
          setLocation(response?.data?.loaction_type);
        }
      }
    );
  };

  useEffect(() => {
    handleGetLocationData(searchTerm);
  }, []);

  const debouncedLocationData = useCallback(
    debounce((searchTerm) => {
      handleGetLocationData(searchTerm);
    }, 500),
    []
  );

  const handleInputChange = (val) => {
    // const newSearchTerm = e.target.value;
    // setSearchTerm(newSearchTerm);
    debouncedLocationData(val);
  };

  const handleDeleteShow = () => {
    setTitleText(t("SURE_DELETE_LOCATION"));
    setIsDeleting(true);
    setIsShowConfirm(true);
  };

  const handleStatusShow = () => {
    setTitleText(t("SURE_UPDATE_STATUS"));
    setIsDeleting(false);
    setIsShowConfirm(true);
  };

  const handleLocationDelete = (id) => {
    setIsLoading(true);
    IndependicareApi.deleteLocationType(userToken, id).then((response) => {
      const ResponseCode = STATUS_MSG[response?.data.code];
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/");
        setIsShowConfirm(false);
      } else {
        if (response.code == STATUS_CODE.SUCCESS) {
          Toster(t("LOCATION_DELETED_SUCCESSFULLY"), "success");
          handleGetLocationData(searchTerm);
          handleDeleteClose();
          setIsShowConfirm(false);
        } else if (
          response.code == STATUS_CODE.VALIDATIONS_ERROR ||
          response.data.code === STATUS_CODE.VALIDATIONS_ERROR
        ) {
          Toster(response.data.message, "error");
          setIsShowConfirm(false);
        } else {
          Toster(t(ResponseCode), "error");
          setIsShowConfirm(false);
        }
      }
    });
  };

  const handleLocationStatusUpdate = (id) => {
    setIsLoading(true);
    IndependicareApi.updateStatusLocation(userToken, id).then((response) => {
      const ResponseCode = STATUS_MSG[response?.data.code];
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/");
        setIsShowConfirm(false);
      } else {
        if (response.code == STATUS_CODE.SUCCESS) {
          Toster(t("UPDATE_STATUS_SUCCESS"), "success");
          handleGetLocationData(searchTerm);
          handleDeleteClose();
          setIsShowConfirm(false);
        } else if (
          response.code == STATUS_CODE.VALIDATIONS_ERROR ||
          response.data.code === STATUS_CODE.VALIDATIONS_ERROR
        ) {
          Toster(response.data.message, "error");
          setIsShowConfirm(false);
        } else {
          Toster(t(ResponseCode), "error");
          setIsShowConfirm(false);
        }
      }
    });
  };

  const handleConfirm = () => {
    setIsLoading(true);
    isDeleting
      ? handleLocationDelete(locationId)
      : handleLocationStatusUpdate(locationId);
  };

  return (
    <>
      <div className={styles.seetingRightSidebar}>
        <div className={styles.sechSeetingmain}>
          <Container>
            <Row className="align-items-center">
              <Col className="setSearchInputField">
                <CustomSearchField
                  setSearchQuery={(val) => {
                    setSearchTerm(val);
                    handleInputChange(val);
                  }}
                />
              </Col>
              <Col>
                <div className="modelButtonPropwerty">
                  <Button
                    className="modalTextButton"
                    type="button"
                    onClick={() => {
                      handleShow();
                      setIsEdit(false);
                      setLocationId();
                    }}
                  >
                    {t("ADD_NEW")}
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {location?.length !== 0 ? (
          <div className={styles.settingPermissionTable}>
            <Table className="customTable" striped>
              {isLoading && <Loader />}
              <thead>
                <tr>
                  <th className={styles.settingBackground}>{t("TITLE")}</th>
                  <th className={styles.settingBackground}>{t("STATUS")}</th>
                  <th className={styles.settingBackgroundAction}>
                    {t("ACTION")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {location?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className={styles.caregiverSourceText}>
                        {item.title}
                      </td>
                      <td className={styles.caregiverSourceText}>
                        {item.is_active == ACTIVE_TYPE.IS_ACTIVE
                          ? "active"
                          : "inactive"}
                      </td>
                      <td className={styles.settingDotImage}>
                        <Dropdown className={styles.setEventDropdown}>
                          <Dropdown.Toggle
                            variant=""
                            id="dropdown-basic"
                            className="custom-dropdown-toggle p-0 border-0"
                          >
                            <MoreHorizIcon />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              href="#"
                              className={styles.caregiverdropdownItem}
                              onClick={() => {
                                handleShow();
                                setIsEdit(true);
                                setLocationId(item.id);
                              }}
                            >
                              <span>{t("EDIT")}</span>
                              <Image src={edit} className="dropdownIcons" />
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="#"
                              className={styles.caregiverdropdownItem}
                              onClick={() => {
                                handleDeleteShow();
                                setLocationId(item.id);
                              }}
                            >
                              <span>{t("DELETE")}</span>
                              <DeleteOutlineIcon className="iconColor" />
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="#"
                              onClick={() => {
                                handleStatusShow();
                                setLocationId(item.id);
                                setLocationStatus(item.is_active);
                              }}
                              className={styles.caregiverdropdownItem}
                            >
                              <span>
                                {item.is_active === 1 ? "Inactive" : "Active"}
                              </span>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        ) : (
          !isLoading && (
            <p className={styles.listRecoredText}>{t("RECORD_NOT_FOUND")}</p>
          )
        )}
      </div>

      <AddLocationTypeModel
        show={show}
        handleClose={handleClose}
        handleGetData={handleGetLocationData}
        isEdit={isEdit}
        locationId={locationId}
        searchTerm={searchTerm}
      />

      <Confirm
        isShowConfirm={isShowConfirm}
        setIsShowConfirm={setIsShowConfirm}
        titleText={titleText}
        setTitleText={setTitleText}
        isDeleting={isDeleting}
        setIsDeleting={setIsDeleting}
        isLoading={isLoading}
        handleConfirm={handleConfirm}
      />
    </>
  );
}
