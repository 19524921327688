import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form } from "react-bootstrap";
import { ValidationSchema } from "../../../Utils/ValidationSchema";
import selectIcon from "../../../Assests/Images/Vector.png";
import Image from "react-bootstrap/Image";
import style from "./CustomInputField.module.css";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller } from "react-hook-form";
import Select from "react-select";
import CalenderImage from "../../../Assests/Images/clarity_date-line@2x.png";
import audioImage from "../../../Assests/Images/Vector@2x.png";
import audioSpeaker from "../../../Assests/Images/audioSpeaker.png";
import watchImage from "../../../Assests/Images/watchImage.png";
import { Height } from "@mui/icons-material";
import Pagination from "../CustomPagination/Pagination";
// import audioImage from "../../../Assests/Images/Vector@2x.png";

export function CustomInputFields({
  type,
  onChange,
  PlaceHolder,
  label,
  RegisterName,
  register,
  formState,
  setValue,
}) {
  const { t } = useTranslation();

  // disable space on key press input
  const handleKeyPress = (e, t) => {
    // Check if the pressed key is a space
    if (e.key === " ") {
      // Prevent the default action (inserting the space)
      e.preventDefault();
    }

    // If the input value starts with a space, trim it
    if (e.target.value.startsWith(" ")) {
      setValue(e.target.name, e.target.value.trim());
    }
  };

  return (
    <div>
      <Form.Group className="formPadding" controlId={`input-${RegisterName}`}>
        <Form.Label>{label}</Form.Label>
        <Form.Control
          type={type}
          placeholder={PlaceHolder}
          // {...register(RegisterName, ValidationSchema[RegisterName])}
          {...register(RegisterName, {
            ...ValidationSchema[RegisterName],
            onChange: (e) => handleKeyPress(e), // Restrict key presses
          })}
          isInvalid={!!formState.errors[RegisterName]}
          // onChange={onChange}
        />

        <Form.Control.Feedback type="invalid">
          {t(formState.errors[RegisterName]?.message)}
        </Form.Control.Feedback>
      </Form.Group>
    </div>
  );
}

export function CustomPasswordInputField({
  type,
  label,
  onChange,
  PlaceHolder,
  RegisterName,
  register,
  formState,
  setValue,
}) {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  // disable space on key press input
  const handleKeyPress = (e, t) => {
    if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div>
      <Form.Group
        className="passwordFieldProperty formPadding"
        controlId={`input-${RegisterName}`}
      >
        <Form.Label>{label}</Form.Label>
        <Form.Control
          type={showPassword ? "text" : type}
          placeholder={PlaceHolder}
          // {...register(RegisterName, ValidationSchema[RegisterName])}
          {...register(RegisterName, {
            ...ValidationSchema[RegisterName],
            onChange: (e) => handleKeyPress(e), // Restrict key presses
          })}
          isInvalid={!!formState.errors[RegisterName]}
          // onChange={onChange}
        />
        <div className="selectIconPropertyPassword d-flex justify-content-end">
          {showPassword ? (
            <VisibilityOffIcon
              className="passwordIcon"
              onClick={togglePasswordVisibility}
            />
          ) : (
            <RemoveRedEyeIcon
              className="passwordIcon"
              onClick={togglePasswordVisibility}
            />
          )}
        </div>
        <Form.Control.Feedback type="invalid">
          {t(formState.errors[RegisterName]?.message)}
        </Form.Control.Feedback>
      </Form.Group>
    </div>
  );
}
export function CustomSelectFields({
  value,
  onChangeHandler,
  label,
  PlaceHolder,
  RegisterName,
  register,
  options,
  formState,
}) {
  const { t } = useTranslation();

  return (
    <Form.Group className="formPadding selectFieldMargin mt-1">
      <Form.Label
        style={{
          color: "#333333",
          fontFamily: "Inter",
          fontWeight: 500,
          fontSize: "15px",
          lineHeight: "21.78px",
        }}
      >
        {label}
      </Form.Label>
      <Form.Select
        // value={value}
        style={{
          color: "#555555", // Set your desired placeholder color here
          fontFamily: "Inter, sans-serif", // Set your desired font family here
          fontWeight: 400, // Set your desired font weight here
          fontSize: "12px", // Set your desired font size here
          lineHeight: "18.15px", // Set your desired line height here
          backgroundColor: "#FAFAFA",
        }}
        aria-label="Default select example"
        {...register(RegisterName, ValidationSchema[RegisterName])}
        isInvalid={!!formState.errors[RegisterName]}
        onChange={onChangeHandler}
      >
        <option className="setTextPlaceholder" value="" class="">
          {PlaceHolder}
        </option>
        {options?.map((option, index) => (
          <option key={index} value={option.id}>
            {option.name}
          </option>
        ))}
      </Form.Select>
      <Form.Control.Feedback type="invalid">
        {t(formState.errors[RegisterName]?.message)}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

export function CustomMultiSelectFields({
  value,
  onChangeHandler,
  label,
  PlaceHolder,
  RegisterName,
  register,
  options,
  formState,
}) {
  const { t } = useTranslation();
  const customStyles = {
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#828282",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#555555", // Set your desired placeholder color here
      fontFamily: "Inter, sans-serif", // Set your desired font family here
      fontWeight: 400, // Set your desired font weight here
      fontSize: "15px", // Set your desired font size here
      lineHeight: "18.15px", // Set your desired line height here
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#74b6f3" : "#E3E3E3", // Set your desired border color here
      borderWidth: "1px", // Set your desired border width here
      height: "50px",
      fontSize: "15px",
      fontFamily: "Inter, sans-serif", // Set your desired font family here
      fontWeight: 400, // Set your desired font weight here
      boxShadow: state.isFocused ? "0 0 0 5px #bedcfc" : provided.boxShadow,
      "&:hover": {
        borderColor: state.isFocused ? "#74b6f3" : "#E3E3E3", // Maintain the border color on hover
      },
    }),
    clearIndicator: (provided) => ({
      ...provided,
      display: "none", // Hide the clear indicator
    }),
  };
  return (
    <Form.Group className="formPadding selectFieldMargin mt-1">
      <Form.Label
        style={{
          color: "#333333",
          fontFamily: "Inter",
          fontWeight: 500,
          fontSize: "18px",
          lineHeight: "21.78px",
        }}
      >
        {label}
      </Form.Label>
      <Select
        value={value}
        isMulti
        options={options.map((option) => ({
          value: option.id,
          label: option.name,
        }))}
        onChange={(selectedOptions) => {
          onChangeHandler(selectedOptions);
        }}
        styles={customStyles}
        placeholder={PlaceHolder} // Set your desired placeholder text here
        className={`react-select-container ${
          !!formState.errors[RegisterName] ? "is-invalid" : ""
        }`}
        classNamePrefix="react-select"
      />

      <Form.Control.Feedback type="invalid">
        {t(formState.errors[RegisterName]?.message)}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

export function CustomSelectFieldsForLevel({
  value,
  onChangeHandler,
  label,
  PlaceHolder,
  RegisterName,
  register,
  options,
  formState,
}) {
  const { t } = useTranslation();

  return (
    <Form.Group className="formPadding selectFieldMargin">
      <div className="selectIconProperty d-flex justify-content-end">
        <Image
          src={selectIcon}
          className="selectIconProperty d-flex justify-content-end"
        />
      </div>
      <Form.Label>{label}</Form.Label>
      <Form.Select
        aria-label="Default select example"
        {...register(RegisterName, ValidationSchema[RegisterName])}
        isInvalid={!!formState.errors[RegisterName]}
        onChange={onChangeHandler}
      >
        <option className="setTextPlaceholder" value="">
          {PlaceHolder}
        </option>
        {options?.map((option, index) => (
          <option key={index} value={option.id}>
            {option.level}
          </option>
        ))}
      </Form.Select>
      <Form.Control.Feedback type="invalid">
        {t(formState.errors[RegisterName]?.message)}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

export function CustomInputFieldsModelForm({
  type,
  onChange,
  PlaceHolder,
  label,
  RegisterName,
  register,
  formState,
  className,
  value,
}) {
  const { t } = useTranslation();
  return (
    <div>
      <Form.Group className={className} controlId={`input-${RegisterName}`}>
        <Form.Label>{label}</Form.Label>
        <Form.Control
          type={type}
          placeholder={PlaceHolder}
          {...register(RegisterName, ValidationSchema[RegisterName])}
          isInvalid={!!formState.errors[RegisterName]}
          onChange={onChange}
          value={value}
        />

        <Form.Control.Feedback type="invalid">
          {t(formState.errors[RegisterName]?.message)}
        </Form.Control.Feedback>
      </Form.Group>
    </div>
  );
}

export function CustomPasswordInputFieldsModelForm({
  type,
  label,
  onChange,
  PlaceHolder,
  RegisterName,
  register,
  formState,
}) {
  const { t } = useTranslation();
  return (
    <div>
      <Form.Group className="formPadding" controlId={`input-${RegisterName}`}>
        <Form.Label>{label}</Form.Label>
        <Form.Control
          type={type}
          placeholder={PlaceHolder}
          {...register(RegisterName, ValidationSchema[RegisterName])}
          isInvalid={!!formState.errors[RegisterName]}
          onChange={onChange}
        />

        <Form.Control.Feedback type="invalid">
          {t(formState.errors[RegisterName]?.message)}
        </Form.Control.Feedback>
      </Form.Group>
    </div>
  );
}
export function CustomSelectFieldsModelForm({
  value,
  onChangeHandler,
  label,
  PlaceHolder,
  RegisterName,
  register,
  options,
  formState,
  type,
  onChange,
  className,
  onClick,
}) {
  const { t } = useTranslation();

  return (
    <Form.Group className={className}>
      <div className="selectIconPropertyModel d-flex justify-content-end">
        <Image
          src={selectIcon}
          className="selectIconProperty d-flex justify-content-end"
        />
      </div>
      <Form.Label>{label}</Form.Label>
      <Form.Select
        aria-label="Default select example"
        {...register(RegisterName, ValidationSchema[RegisterName])}
        isInvalid={!!formState.errors[RegisterName]}
        onChange={onChange}
        onClick={onClick}
      >
        <option className={className} value="">
          {PlaceHolder}
        </option>
        {options?.map((option, index) => (
          <option key={index} value={option.id}>
            {option.name}
          </option>
        ))}
      </Form.Select>
      <Form.Control.Feedback type="invalid">
        {t(formState.errors[RegisterName]?.message)}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

export const CustomDatePicker = ({
  control,
  RegisterName,
  formState,
  label,
  PlaceHolder,
  className,
  rules,
  defaultValue,
  register,
}) => {
  const error = formState.errors[RegisterName];
  const { t } = useTranslation();
  return (
    <div className={className}>
      <label>{label}</label>
      <Controller
        control={control}
        name={RegisterName}
        rules={rules}
        defaultValue={defaultValue}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <div>
            <DatePicker
              className="form-control"
              selected={value}
              onChange={onChange}
              onBlur={onBlur}
              ref={ref}
              dateFormat="MM/dd/yyyy"
              placeholderText={PlaceHolder}
              isInvalid={!!error}
              onKeyDown={(e) => e.preventDefault()} // Prevents typing
            />
            <div className="dateCalenderImage">
              <Image
                src={CalenderImage}
                alt="calenderImage"
                style={{
                  position: "absolute",
                  top: "8px",
                  right: "16px",
                  width: "32px",
                  pointerEvents: "none",
                }}
              />
            </div>
            {error && (
              <Form.Control.Feedback
                type="invalid"
                style={{ display: "block" }}
              >
                {t(error.message)}
              </Form.Control.Feedback>
            )}
          </div>
        )}
      />
    </div>
  );
};

export const CustomSelectFieldsSeachState = ({
  value,
  onChangeHandler,
  label,
  PlaceHolder,
  RegisterName,
  register,
  options,
  formState,
  errors,
}) => {
  const { t } = useTranslation();
  const errorMessage = formState.errors[RegisterName]?.message;
  const hasError = !!errors;

  return (
    <Form.Group
      className="formPadding selectFieldMargin"
      style={{ paddingTop: "10px" }}
    >
      {/* <div className="selectIconProperty d-flex justify-content-end">
        <Image
          src={selectIcon}
          alt="select icon"
          className="selectIconProperty d-flex justify-content-end"
        />
      </div> */}
      <Form.Label>{label}</Form.Label>
      <Controller
        name={RegisterName}
        control={register.control}
        rules={ValidationSchema[RegisterName]}
        render={({ field }) => (
          <Select
            {...field}
            options={options}
            placeholder={PlaceHolder}
            classNamePrefix="react-select"
            onChange={(selectedOption) => {
              field.onChange(selectedOption);
              onChangeHandler(selectedOption);
            }}
            value={options.find((option) => option.value === field.value)}
            // className={
            //   errorMessage ? "react-select react-select-error" : "react-select"
            // }
            className={`${
              errorMessage ? "react-select react-select-error" : "react-select"
            }`}
            // styles={{ height: "50px" }}
          />
        )}
      />
      {errorMessage && (
        <Form.Control.Feedback type="invalid" className="d-block">
          {t(errorMessage)}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

export function CustomAudioInputField({
  type,
  onChange,
  PlaceHolder,
  label,
  RegisterName,
  register,
  formState,
  className,
  value,
  onClick,
  errorMessage,
}) {
  const { t } = useTranslation();
  const fileInputRef = useRef(null);

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div>
      <Form.Group className={className} controlId={`input-${RegisterName}`}>
        <Form.Label>{label}</Form.Label>
        <Form.Control
          type="text"
          placeholder={PlaceHolder}
          onClick={handleClick}
          // accept=".mp3, .wav, .aac, .flac, .ogg, .wma, .aiff, .alac, .m4a, .amr" // Limit file types
          isInvalid={!!formState.errors[RegisterName]}
          value={value ? value.name : ""}
        />
        <input
          type="file"
          // accept="audio/mp3"
          accept=".mp3, .wav, .aac, .flac, .ogg, .wma, .aiff, .alac, .m4a, .amr" // Limit file types
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={onChange}
        />
        <div className={style.parentIconButton}>
          <div className={style.audioImageContainer}>
            <Image
              src={audioImage}
              alt="audioImage"
              className={style.audioImage}
            />
          </div>
          <div className={style.browseSpeakerButton}>
            <div className={style.audioSpeakerContainer}>
              <Image
                src={audioSpeaker}
                alt="audioSpeaker"
                className={style.audioSpeaker}
              />
            </div>
            <div className={style.audioButtonContainer}>
              <Button
                type="button"
                className={style.selectAudioButton}
                onClick={handleClick}
              >
                {t("Browse")}
              </Button>
            </div>
          </div>
        </div>
        <Form.Control.Feedback type="invalid">
          {t(formState.errors[RegisterName]?.message || errorMessage)}
        </Form.Control.Feedback>
      </Form.Group>
    </div>
  );
}

export function CustomAudioPromptInput({
  type,
  onChange,
  PlaceHolder,
  label,
  RegisterName,
  register,
  formState,
  className,
  value,
  onClick,
  errorMessage,
}) {
  const { t } = useTranslation();
  const fileInputRef = useRef(null);

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div>
      <Form.Group className={className} controlId={`input-${RegisterName}`}>
        <Form.Label>{label}</Form.Label>
        <Form.Control
          type="text"
          placeholder={PlaceHolder}
          // readOnly
          onClick={handleClick}
          // accept=".mp3, .wav, .aac, .flac, .ogg, .wma, .aiff, .alac, .m4a, .amr" // Limit file types
          isInvalid={!!formState.errors[RegisterName]}
          value={value ? value.name : ""}
        />
        <input
          type="file"
          // accept="audio/mp3"
          accept=".mp3, .wav, .aac, .flac, .ogg, .wma, .aiff, .alac, .m4a, .amr" // Limit file types
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={onChange}
        />
        <div className={style.parentIconButton1}>
          <div className={style.audioImageContainer}>
            <Image
              src={audioImage}
              alt="audioImage"
              className={style.audioImage}
            />
          </div>
          <div className={style.browseSpeakerButton}>
            <div className={style.audioSpeakerContainer}>
              <Image
                src={audioSpeaker}
                alt="audioSpeaker"
                className={style.audioSpeaker}
              />
            </div>
            <div className={style.audioButtonContainer}>
              <Button
                type="button"
                className={style.selectAudioButton}
                onClick={handleClick}
              >
                {t("Browse")}
              </Button>
            </div>
          </div>
        </div>
        <Form.Control.Feedback type="invalid">
          {t(formState.errors[RegisterName]?.message)}
        </Form.Control.Feedback>
      </Form.Group>
    </div>
  );
}

export function CustomRadioFieldsModelForm({
  value,
  onChangeHandler,
  label,
  RegisterName,
  register,
  options,
  formState,
  className,
  onClick,
}) {
  const { t } = useTranslation();

  return (
    <Form.Group className={className}>
      <Form.Label>{label}</Form.Label>
      <div className="radio-group">
        {options?.map((option, index) => (
          <Form.Check
            key={index}
            type="radio"
            id={`${RegisterName}-${option.id}`}
            label={option.name}
            value={option.id}
            {...register(RegisterName, {
              required: t("This field is required"),
              validate: (value) => !!value || t("This field is required"),
            })}
            isInvalid={!!formState.errors[RegisterName]}
            onClick={onClick}
          />
        ))}
      </div>
      <Form.Control.Feedback type="invalid">
        {t(formState.errors[RegisterName]?.message)}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

export function CustomTextareaFields({
  onChange,
  PlaceHolder,
  label,
  RegisterName,
  register,
  formState,
}) {
  const { t } = useTranslation();
  return (
    <div>
      <Form.Group
        className="formPadding textArealabel"
        controlId={`textarea-${RegisterName}`}
      >
        <Form.Label>{label}</Form.Label>
        <Form.Control
          as="textarea"
          placeholder={PlaceHolder}
          {...register(RegisterName, ValidationSchema[RegisterName])}
          isInvalid={!!formState.errors[RegisterName]}
          onChange={onChange}
        />
        <Form.Control.Feedback type="invalid">
          {t(formState.errors[RegisterName]?.message)}
        </Form.Control.Feedback>
      </Form.Group>
    </div>
  );
}

export function CustomSelectFieldsPredefined({
  value,
  onChangeHandler,
  label,
  PlaceHolder,
  RegisterName,
  register,
  options,
  formState,
  type,
  onChange,
  className,
  onClick,
}) {
  const { t } = useTranslation();

  return (
    <Form.Group className={className}>
      <div className={style.vectorIconField}>
        <Image src={selectIcon} className={style.vectorIcon} />
      </div>
      <Form.Label>{label}</Form.Label>
      <Form.Select
        aria-label="Default select example"
        {...register(RegisterName, ValidationSchema[RegisterName])}
        isInvalid={!!formState.errors[RegisterName]}
        onChange={onChange}
        onClick={onClick}
      >
        <option className={className} value="">
          {PlaceHolder}
        </option>
        {options?.map((option, index) => (
          <option key={index} value={option.id}>
            {option.name}
          </option>
        ))}
      </Form.Select>
      <Form.Control.Feedback type="invalid">
        {t(formState.errors[RegisterName]?.message)}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

export function CustomInputFieldForTime({
  type,
  onChange,
  PlaceHolder,
  label,
  RegisterName,
  register,
  formState,
  className,
  value,
}) {
  const { t } = useTranslation();
  return (
    <div>
      <Form.Group className={className} controlId={`input-${RegisterName}`}>
        <Form.Label>{label}</Form.Label>
        <Form.Control
          type={type}
          placeholder={PlaceHolder}
          {...register(RegisterName, ValidationSchema[RegisterName])}
          isInvalid={!!formState.errors[RegisterName]}
          onChange={onChange}
          value={value}
        />
        {/* <div className={style.watchIconMain}>
          <Image src={watchImage} alt="watch" className={style.watchIcon} />
        </div> */}

        <Form.Control.Feedback type="invalid">
          {t(formState.errors[RegisterName]?.message)}
        </Form.Control.Feedback>
      </Form.Group>
    </div>
  );
}

//
export function CustomMultiSelectFieldsPagination({
  value,
  onChangeHandler,
  label,
  PlaceHolder,
  RegisterName,
  register,
  options,
  formState,
  count,
  pageLimit,
  currentPage,
  onPageChange,
  fetchOptions, // Function to fetch paginated options
}) {
  const { t } = useTranslation();
  const [loadedOptions, setLoadedOptions] = useState([]);
  const [page, setPage] = useState(currentPage);

  // Load initial options on component mount or when currentPage changes
  useEffect(() => {
    fetchMoreOptions(page);
  }, [page]);

  // Fetch more options when scrolling to the bottom of the dropdown
  const fetchMoreOptions = async (pageNumber) => {
    const newOptions = await fetchOptions(pageNumber, pageLimit);
    setLoadedOptions((prev) => [...prev, ...newOptions]);
  };

  // Custom styles for the select field
  const customStyles = {
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#828282",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#555555",
      fontFamily: "Inter, sans-serif",
      fontWeight: 400,
      fontSize: "15px",
      lineHeight: "18.15px",
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#74b6f3" : "#E3E3E3",
      borderWidth: "1px",
      // height: "50px",
      fontSize: "15px",
      fontFamily: "Inter, sans-serif",
      fontWeight: 400,
      boxShadow: state.isFocused ? "0 0 0 5px #bedcfc" : provided.boxShadow,
      "&:hover": {
        borderColor: state.isFocused ? "#74b6f3" : "#E3E3E3",
      },
    }),
    clearIndicator: (provided) => ({
      ...provided,
      display: "none",
    }),
  };

  return (
    <Form.Group className="formPadding selectFieldMargin mt-1">
      <Form.Label
        style={{
          color: "#333333",
          fontFamily: "Inter",
          fontWeight: 500,
          fontSize: "18px",
          lineHeight: "21.78px",
        }}
      >
        {label}
      </Form.Label>
      <Select
        value={value}
        isMulti
        options={loadedOptions.map((option) => ({
          value: option.id,
          label: option.name,
        }))}
        onChange={(selectedOptions) => {
          onChangeHandler(selectedOptions);
        }}
        onMenuScrollToBottom={() => {
          setPage((prevPage) => prevPage + 1); // Load the next page when scrolled to bottom
        }}
        styles={customStyles}
        placeholder={PlaceHolder}
        className={`react-select-container ${
          !!formState.errors[RegisterName] ? "is-invalid" : ""
        }`}
        classNamePrefix="react-select"
      />
      <Form.Control.Feedback type="invalid">
        {t(formState.errors[RegisterName]?.message)}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

// assign peron
export function CustomSelectAssignPerson({
  value,
  onChangeHandler,
  label,
  PlaceHolder,
  RegisterName,
  register,
  options,
  formState,
}) {
  const { t } = useTranslation();

  return (
    <Form.Group className="formPadding selectFieldMargin mt-1">
      <Form.Label
        style={{
          color: "#333333",
          fontFamily: "Inter",
          fontWeight: 500,
          fontSize: "18px",
          lineHeight: "21.78px",
        }}
      >
        {label}
      </Form.Label>
      <Form.Select
        value={value}
        style={{
          color: "#555555", // Set your desired placeholder color here
          fontFamily: "Inter, sans-serif", // Set your desired font family here
          fontWeight: 400, // Set your desired font weight here
          fontSize: "15px", // Set your desired font size here
          lineHeight: "18.15px", // Set your desired line height here
          backgroundColor: "#FAFAFA",
        }}
        aria-label="Default select example"
        {...register(RegisterName, ValidationSchema[RegisterName])}
        isInvalid={!!formState.errors[RegisterName]}
        onChange={onChangeHandler}
      >
        <option className="setTextPlaceholder" value="" class="">
          {PlaceHolder}
        </option>
        {options?.map((option, index) => (
          <option key={index} value={option.id}>
            {option.name}
          </option>
        ))}
      </Form.Select>
      <Form.Control.Feedback type="invalid">
        {t(formState.errors[RegisterName]?.message)}
      </Form.Control.Feedback>
    </Form.Group>
  );
}
