import React from "react";
import { Container } from "react-bootstrap";
import styles from "../Footer/Footer.module.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { CONSTANT } from "../../Utils/Constant";

function Footer() {
  const { t } = useTranslation();
  let currentYear = new Date().getFullYear();
  const location = useLocation();
  const pathName = location.pathname;

  const backgroundColor =
    pathName === "/"
      ? CONSTANT.HOME_PAGE_FOOTER_COLOR
      : CONSTANT.DASHBOARD_FOOTER_COLOR;
  const textColor =
    pathName === "/"
      ? CONSTANT.HOME_PAGE_FOOTER_TEXT_COLOR
      : CONSTANT.DASHBOARD_FOOTER_TEXT_COLOR;

  return (
    <div
      className={styles.mainFooter}
      style={{
        backgroundColor: backgroundColor,
        position: "fixed",
        bottom: "0px",
      }}
    >
      <Container>
        <div className={styles.footerSec}>
          <h3 style={{ color: textColor }}>
            @{currentYear}
            {t("INDEPENDICARE_ALL_RIGHTS_RESERVED")}
          </h3>
          <div className={styles.socialLink}>
            <span>
              <Link to="/privacy-policy" style={{ color: textColor }}>
                {t("PRIVACY_POLICY")}
              </Link>
            </span>
            <span>
              <Link to="/terms-conditions" style={{ color: textColor }}>
                {t("TERMS_AND_CONDITION")}
              </Link>
            </span>
          </div>
        </div>
      </Container>
    </div>
  );
}
export default Footer;
