export const CONSTANT = {
  HOME_PAGE_FOOTER_COLOR: "#FFFFFF",
  HOME_PAGE_FOOTER_TEXT_COLOR: "#202020",
  DASHBOARD_FOOTER_COLOR: "#1D4488",
  DASHBOARD_FOOTER_TEXT_COLOR: "#FFFFFF",
  STAKEHOLDER_KEY: "stakeholder",
  CAREGIVER_KEY: "caregiver",
};

export const ACTIVE_TYPE = {
  IS_ACTIVE: 1,
  IS_INACTIVE: 0,
};

export const MESSAGE_TYPE = {
  TIME_MESSAGE: "time-message",
  LOCATION_MESSAGE: "location-message",
};

export const DATE_FORMATES = {
  DD_MM_YY: "DD/MM/YYYY",
  MM_DD_YY: "MM/DD/YYYY",
};

export const EVENT_TYPE = {
  EVENTS: "event",
  DAILY_EVENT: "daily-event",
};
