import React from "react";
import { Button, Image, Modal } from "react-bootstrap";
import styles from "./Confirm.module.css";
import { useTranslation } from "react-i18next";
import CancelIcon from "@mui/icons-material/Cancel";
import deleteImage from "../../../Assests/Images/delete_image.png";
import Loader from "../../LoaderComponent/LoaderComponent";

export default function Confirm({
  isShowConfirm,
  setIsShowConfirm,
  titleText,
  setTitleText,
  isDeleting,
  setIsDeleting,
  isLoading,
  handleConfirm,
}) {
  const { t } = useTranslation();

  const onHandleClose = () => {
    setIsDeleting(false);
    setIsShowConfirm(false);
    setTitleText("");
  };

  const onHandleConfirm = () => {
    handleConfirm();
  };

  return (
    <>
      <Modal show={isShowConfirm} onHide={onHandleClose}>
        <Modal.Header className="d-flex justify-content-between">
          <p className={styles.Eventproperty}>{t("Alert")}</p>
          <CancelIcon className="closeIconProperty" onClick={onHandleClose} />
        </Modal.Header>
        <Modal.Body>
          {isLoading && <Loader />}
          {isDeleting && (
            <div className={styles.deleteClass}>
              <Image src={deleteImage} />
            </div>
          )}
          <p className={styles.titleText}>{titleText}</p>
          <Button
            type="submit"
            className="AddSiteSubmitButton"
            onClick={() => {
              onHandleConfirm();
            }}
          >
            {t("OK")}
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
}
