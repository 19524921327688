import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../Header/Header";
import banner from "../../Assests/Images/BannerImg.png";
import Carousel from "react-bootstrap/Carousel";
import Footer from "../Footer/Footer";
import CareImg from "../../Assests/Images/CareImg1.png";
import TagImg from "../../Assests/Images/yesIcon.png";
import { Link } from "react-router-dom";
import "../../Assests/css/style.css";
import { useTranslation } from "react-i18next";
import styles from "../Home/Home.module.css";

function Home() {
  const { t } = useTranslation();

  return (
    <>
      {/*======Header-Section=======*/}
      <Header />
      {/*======Slider section=======*/}
      <section className={styles.headerSpace}>
        <div className={styles.bannerSection}>
          <Carousel data-bs-theme="light">
            <Carousel.Item interval={5000}>
              <img className="d-block w-100" src={banner} alt="First slide" />

              <div className={styles.bannerHeading}>
                <h5>{t("WELCOME_TO_INDEPENDICARE")}</h5>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={4000}>
              <img className="d-block w-100" src={banner} alt="Second slide" />

              <div className={styles.bannerHeading}>
                <h5>{t("WELCOME_TO_INDEPENDICARE")}</h5>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={3000}>
              <img className="d-block w-100" src={banner} alt="Third slide" />

              <div className={styles.bannerHeading}>
                <h5>{t("WELCOME_TO_INDEPENDICARE")}</h5>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </section>
      {/*======About-Section=======*/}

      <section>
        <Container>
          <div className={styles.textStyle}>
            <h3>
              {t("WELCOME_TO")} <span>{t("INDEPENDICARE")}</span>{" "}
              {t("OUR_GOAL")}
            </h3>
            <p>{t("LOREM_IPSUM_TEXT")}</p>
            <span className={styles.borderLine}></span>
          </div>
        </Container>
      </section>
      {/*======About-Section=======*/}
      <section>
        <div className={styles.aboutCaregiver}>
          <Container>
            <div className={styles.aboutSec}>
              <Row>
                <Col className={styles.mauto} md={6} sm={12}>
                  <div className={styles.aboutImg}>
                    <img src={CareImg} alt="First slide" />
                  </div>
                </Col>
                <Col md={6} sm={12}>
                  <div className={styles.aboutTxt}>
                    <h3>{t("ALWAYS_DEDICATED_AND_DEVOTED")}</h3>
                    <p>{t("ABOUT_DUMMY_TEXT")}</p>
                    <div className={styles.tagIdSec}>
                      <div className={styles.tagSec}>
                        <Link to="/">
                          <img src={TagImg} alt="First slide" />
                          <span>{t("DEVICE_TAG_ID_1")}</span>
                        </Link>
                      </div>

                      <div className={styles.tagSec}>
                        <Link to="/">
                          <img src={TagImg} alt="First slide" />
                          <span>{t("DEVICE_TAG_ID_2")}</span>
                        </Link>
                      </div>
                    </div>
                    <div className={styles.tagIdSec}>
                      <div className={styles.tagSec}>
                        <Link to="/">
                          <img src={TagImg} alt="First slide" />
                          <span>{t("DEVICE_TAG_ID_3")}</span>
                        </Link>
                      </div>

                      <div className={styles.tagSec}>
                        <Link to="/">
                          <img src={TagImg} alt="First slide" />
                          <span>{t("DEVICE_TAG_ID_4")}</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </section>
      {/*======Footer-Section=======*/}
      <Footer />
    </>
  );
}
export default Home;
