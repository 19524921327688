import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Dropdown from "react-bootstrap/Dropdown";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useTranslation } from "react-i18next";
import IndependicareApi from "../../../Helpers/Api";
import {
  Row,
  Col,
  Container,
  Table,
  Image,
  Button,
  Modal,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import CancelIcon from "@mui/icons-material/Cancel";
import CustomSearchField from "../../CustomComponent/CustomSearchField/CustomSearchField";
import styles from "../Eventtype/Event.module.css";
import edit from "../../../Assests/Images/edit.png";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODE } from "../../../Utils/StatusCode";
import { STATUS_MSG } from "../../../Utils/StatusMessage";
import Toster from "../../../Toster/Toster";
import { clearUserState } from "../../../redux/slices/userSlice";
import { useNavigate } from "react-router-dom";
import deleteImage from "../../../Assests/Images/delete_image.png";
import Loader from "../../LoaderComponent/LoaderComponent";
import { ACTIVE_TYPE, EVENT_TYPE } from "../../../Utils/Constant";
import EventModal from "./EventModal";

function DailyEventType() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { reset, setValue, clearErrors } = useForm({
    mode: "onChange",
  });

  const [isLoading, setIsLoading] = useState(false);
  const token = useSelector((state) => state.user.userToken);
  const [list, setList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedLevelStatus, setSelectedLevelStatus] = useState();
  const [showModal, setShowModal] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [eventId, setEventId] = useState();

  const [showModal1, setShowModal1] = useState(false);

  const [showModal2, setShowModal2] = useState(false);
  const handleCloseModal2 = () => setShowModal2(false);
  const handleShowModal2 = () => setShowModal2(true);

  const [showStatus, setShowStatus] = useState(false);
  const [isShowModal, setIsShowModal] = useState({ show: false, id: "" });
  const handleCloseStatus = () => setShowStatus(false);
  const handleShowStatus = () => setShowStatus(true);

  const handleShowModal = () => {
    setIsShowModal((prev) => ({ ...prev, show: true }));
  };

  useEffect(() => {
    handelGetDailyEvents();
  }, []);

  const handelGetDailyEvents = () => {
    setIsLoading(true);
    IndependicareApi.fetchEvent(token, EVENT_TYPE.DAILY_EVENT).then(
      (response) => {
        if (
          response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODE.INACTIVE_USER
        ) {
          dispatch(clearUserState());
          Toster(t("session expired"), "error");
          navigate("/signin");
          setIsLoading(false);
        } else if (response?.code == STATUS_CODE.SUCCESS) {
          setList(response?.data?.event_types);
          setIsLoading(false);
        }
      }
    );
  };

  const handleEditEvent = (id) => {
    setIsEdit(true);
    setIsShowModal((prev) => ({ id, show: true }));
  };

  /*=========Delete event type============*/
  const Eventdelete = (eventId) => {
    setIsLoading(true);
    IndependicareApi.deleteEventType(token, eventId).then((response) => {
      setIsLoading(false);
      const ResponseCode = STATUS_MSG[response?.data.code];
      if (response?.code == STATUS_CODE.SUCCESS) {
        Toster(t("EVENT_TYPE_DELETED_SUCCESSFULLY"), "success");
        handelGetDailyEvents();
        setShowModal2(false);
      } else if (
        response?.code === STATUS_CODE.VALIDATIONS_ERROR ||
        response?.data?.code === STATUS_CODE.VALIDATIONS_ERROR
      ) {
        Toster(response?.code?.message, "error");
      } else {
        Toster(t(ResponseCode), "error");
      }
      setShowModal2(false);
    });
  };

  /*=========Update Event Status ===============*/
  const Statusevent = (eventId) => {
    setIsLoading(true);
    IndependicareApi.Eventstatus(token, eventId).then((response) => {
      handelGetDailyEvents();
      setIsLoading(false);
      handleCloseStatus();
    });
  };

  const filteredList = list.filter((item) =>
    item.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      {isLoading && <Loader />}

      <div>
        <Container>
          <Row className="align-items-center">
            <Col className="setSearchInputField">
              <CustomSearchField setSearchQuery={setSearchQuery} />
            </Col>
            <Col>
              <div className="modelButtonPropwerty">
                <Button
                  className="modalTextButton"
                  type="button"
                  onClick={handleShowModal}
                >
                  {t("ADD_NEW")}
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <Container>
          <div className={styles.eventTable}>
            <Table className={styles.customTable} striped>
              <thead className={styles.tableHeadProperty}>
                <tr>
                  <th className={styles.EventalignLeft}>{t("TITLE")}</th>
                  <th className={`${styles.eventAction1} ${styles.alignRight}`}>
                    {t("STATUS")}
                  </th>
                  <th className={`${styles.eventAction} ${styles.alignRight}`}>
                    {t("ACTION")}
                  </th>
                </tr>
              </thead>

              <tbody>
                {filteredList &&
                  filteredList.length > 0 &&
                  filteredList.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className={styles.alignLeft}>{item.title}</td>
                        <td className={styles.alignRight}>
                          {item.is_active == ACTIVE_TYPE.IS_ACTIVE
                            ? "Active"
                            : "Inactive"}
                        </td>
                        <td className={styles.alignRight}>
                          <Dropdown className={styles.setEventDropdown}>
                            <Dropdown.Toggle
                              variant=""
                              id="dropdown-basic"
                              className="custom-dropdown-toggle p-0 border-0"
                            >
                              <MoreHorizIcon />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="custom-dropdown-menu">
                              <div
                                className="d-flex justify-content-around dropdownTextProperty"
                                onClick={() => {
                                  handleEditEvent(item.id);
                                }}
                              >
                                <Dropdown.Item>
                                  <span>{t("EDIT")}</span>
                                  <Image src={edit} className="dropdownIcons" />
                                </Dropdown.Item>
                              </div>
                              <div
                                className="d-flex justify-content-around dropdownTextProperty"
                                onClick={() => {
                                  handleShowModal2();
                                  setEventId(item.id);
                                }}
                              >
                                <Dropdown.Item>
                                  <span>{t("DELETE")}</span>
                                  <DeleteOutlineIcon className="iconColor" />
                                </Dropdown.Item>
                              </div>

                              <div
                                className="d-flex justify-content-around dropdownTextProperty"
                                onClick={() => {
                                  handleShowStatus();
                                  setEventId(item.id);
                                  setSelectedLevelStatus(item.is_active);
                                }}
                              >
                                <Dropdown.Item>
                                  <span>
                                    {item.is_active == ACTIVE_TYPE.IS_ACTIVE
                                      ? "Inactive"
                                      : "Active"}
                                  </span>
                                </Dropdown.Item>
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        </Container>
        <div className={styles.noRecord}>
          <p>
            {!isLoading && filteredList.length == 0 ? t("NO_RECORD_FOUND") : ""}
          </p>
        </div>
      </div>

      {/*=========Event-Delete-Modal==============*/}
      <Modal show={showModal2} onHide={handleCloseModal2}>
        <Modal.Header className="d-flex justify-content-between">
          <p className={styles.Eventproperty}>{t("Alert")}</p>
          <CancelIcon
            className="closeIconProperty"
            onClick={handleCloseModal2}
          />
        </Modal.Header>
        <Modal.Body>
          <div className={styles.deleteClass}>
            <Image src={deleteImage} />

            <p>{t("ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_EVENT_TYPE")}</p>
          </div>

          <Button
            type="submit"
            className="AddSiteSubmitButton"
            onClick={() => {
              Eventdelete(eventId);
            }}
          >
            {t("OK")}
          </Button>
        </Modal.Body>
      </Modal>

      {/*=========Status-Modal==============*/}
      <Modal show={showStatus} onHide={handleCloseStatus}>
        <Modal.Header className="d-flex justify-content-between">
          <p className={styles.Eventproperty}>{t("Alert")}</p>
          <CancelIcon
            className="closeIconProperty"
            onClick={handleCloseStatus}
          />
        </Modal.Header>
        <Modal.Body>
          <div className={styles.deleteClass}>
            {selectedLevelStatus === ACTIVE_TYPE.IS_ACTIVE ? (
              <p>{t("ARE_YOU_SURE_YOU_WANT_TO_INACTIVE_THIS_EVENT_TYPE")}</p>
            ) : (
              <p>{t("ARE_YOU_SURE_YOU_WANT_TO_ACTIVE_THIS_EVENT_TYPE")}</p>
            )}
          </div>

          <Button
            type="submit"
            className="AddSiteSubmitButton"
            onClick={() => {
              Statusevent(eventId);
            }}
          >
            {t("OK")}
          </Button>
        </Modal.Body>
      </Modal>

      {/* Modal for add and update daily event type */}
      <EventModal
        isShowModal={isShowModal}
        setIsShowModal={setIsShowModal}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        handelGetDailyEvents={handelGetDailyEvents}
      />
    </>
  );
}
export default DailyEventType;
