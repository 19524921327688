import React, { useEffect, useState } from "react";
import styles from "../StakeholderDetail/StakeholderDetail.module.css";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";
import { DropdownButton, Dropdown, Image, Modal } from "react-bootstrap";
import PlayAudioBtn from "../../Assests/Images/PlayAudioBtn.png";
import ReactAudioPlayer from "react-audio-player";
import Pagination from "../CustomComponent/CustomPagination/Pagination";
import IndependicareApi from "../../Helpers/Api";
import { STATUS_MSG } from "../../Utils/StatusMessage";
import { STATUS_CODE } from "../../Utils/StatusCode";
import Toster from "../../Toster/Toster";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearUserState } from "../../redux/slices/userSlice";
import Loader from "../LoaderComponent/LoaderComponent";

function Person({ personList, id, count }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.userToken);
  const [list, setList] = useState(personList);

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const pageLimit = 10;
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    const payLoads = {
      id: id,
      page_no: pageNumber,
      pagination_type: "person",
    };
    getList(payLoads);
  };

  const getList = (payLoads) => {
    setIsLoading(true);
    IndependicareApi.getStakeholderDetails(token, payLoads?.id, payLoads).then(
      (response) => {
        const responseCode = STATUS_MSG[response?.data?.code];
        setIsLoading(false);
        if (response?.code === STATUS_CODE.SUCCESS) {
          setList(response?.data?.personList);
        } else if (
          response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODE.INACTIVE_USER
        ) {
          dispatch(clearUserState());
          Toster(t("SESSION_EXPIRED"), "error");
          navigate("/signin");
        } else if (response?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
          Toster(t(responseCode), "error");
        } else {
          Toster(t(responseCode), "error");
        }
      }
    );
  };

  const [showAudioModal, setShowAudioModal] = useState(false);
  const [currentAudio, setCurrentAudio] = useState(null);
  const playAudio = () => {
    setShowAudioModal(true);
  };
  const handleCloseAudioModal = () => setShowAudioModal(false);

  return (
    <>
      <div className="d-flex flex-column justify-content-between h-111 w-100">
        {isLoading && <Loader />}
        <div className={styles.tableResizer}>
          <Table striped responsive>
            <thead className={styles.thead}>
              <tr>
                <th>{t("PERSON_NAME")}</th>
                <th>{t("LANGUAGE_TO_USE")}</th>
                <th>{t("PROMPT_INTRO_TEXT")}</th>
                <th>{t("PROMPT_INTRO_AUDIO")}</th>
                <th>{t("NOTES")}</th>
              </tr>
            </thead>
            <tbody>
              {list?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.person_name}</td>
                    <td>{item.language}</td>
                    <td>{item.prompt_intro_text}</td>
                    <td>
                      <Image
                        src={PlayAudioBtn}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setCurrentAudio(item?.prompt_intro_audio);
                          playAudio();
                          return;
                        }}
                      />
                    </td>
                    <td className={styles.center}>{item.notes}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <Pagination
          count={count}
          pageLimit={pageLimit}
          currentPage={currentPage}
          onPageChange={onPageChange}
        />
      </div>

      <Modal show={showAudioModal} onHide={handleCloseAudioModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className={styles.audioIntro}>
              <h4>{t("PROMPT_INTRO_AUDIO")}</h4>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentAudio && (
            <ReactAudioPlayer
              src={currentAudio}
              autoPlay
              controls
              style={{ width: "95%" }}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
export default Person;
