import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Dropdown from "react-bootstrap/Dropdown";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import edit from "../../Assests/Images/edit.png";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useTranslation } from "react-i18next";
import {
  Row,
  Col,
  Container,
  Table,
  Image,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Addsite from "../../Components/AddSite/AddSite";
import CancelIcon from "@mui/icons-material/Cancel";
import CustomSearchField from "../../Components/CustomComponent/CustomSearchField/CustomSearchField";
import styles from "./Site.module.css";
import IndependicareApi from "../../Helpers/Api";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { STATUS_MSG } from "../../Utils/StatusMessage";
import { STATUS_CODE } from "../../Utils/StatusCode";
import Toster from "../../Toster/Toster";
import { clearUserState } from "../../redux/slices/userSlice";
import moment from "moment";
import { DATE_FORMATES } from "../../Utils/Constant";
import Pagination from "../CustomComponent/CustomPagination/Pagination";
import Loader from "../LoaderComponent/LoaderComponent";
import useDebounce from "../../Helpers/Debounce";
import AddSiteStatus from "./AddSiteStatus";
import deleteImage from "../../Assests/Images/delete_image.png";

function Site() {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => {
    setSiteItem("");
    setShowModal(false);
  };
  const handleShowModal = () => setShowModal(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [siteData, setSiteData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [addedCaregiverListCount, setAddedCaregiverListCount] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showModalStatus, setShowModalStatus] = useState(false);

  const [showModalDelete, setShowModalDelete] = useState(false);
  const [siteIdToDelete, setSiteIdToDelete] = useState(null);

  const [siteItem, setSiteItem] = useState("");

  const tdAlignClass = { flex: 1, alignContent: "center" };

  const pageLimit = 10;

  const debouncedSearch = useDebounce((term) => {
    siteList(1, term);
  }, 500);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    siteList(pageNumber, searchQuery);
  };
  const handleCloseModalStatus = () => {
    setShowModalStatus(false);
    setSelectedStatus(null);
  };

  const handleShowModalStatus = (item) => {
    setSelectedStatus(item);
    setShowModalStatus(true);
  };

  // for delete event model form
  const handleCloseModalDelete = () => {
    setShowModalDelete(false);
  };

  const handleShowModalDelete = () => {
    setShowModalDelete(true);
    setSiteIdToDelete(null);
  };

  // getSite(pageNo, authToken, key)
  const token = useSelector((state) => state.user.userToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const siteList = (page, searchTerm) => {
    setIsLoading(true);
    const userCurrentPage = page;
    const search = searchTerm;
    IndependicareApi.getSite(userCurrentPage, token, search).then((res) => {
      setSearchQuery(searchTerm);
      setCurrentPage(page);
      setIsLoading(false);
      if (res?.code === STATUS_CODE.SUCCESS) {
        let siteList = res?.data?.site_list;
        setAddedCaregiverListCount(res?.data?.count);

        if (Array.isArray(siteList)) {
          siteList = siteList.map((site) => {
            if (site.start_date) {
              // Format the date
              site.start_date = moment(site.start_date).format(
                DATE_FORMATES.DD_MM_YY
              );
            }
            return site;
          });
        }

        setSiteData(siteList);
      } else if (
        res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        res?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
      } else if (res?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(res?.data?.message, "error");
      } else {
        const responseCode = STATUS_MSG[res?.data?.code];
        Toster(t(responseCode), "error");
      }
    });
  };

  useEffect(() => {
    siteList(1, "");
  }, []);

  const activeInactiveFunction = (item) => {
    setSelectedStatus(item);
    handleShowModalStatus(item);
  };

  const deleteSite = async (siteId) => {
    setSiteIdToDelete(siteId);
    setShowModalDelete(true);
  };

  const deleteContent = async () => {
    setIsLoading(true);
    try {
      const response = await IndependicareApi.deleteSite(siteIdToDelete, token);
      const responseCode = STATUS_MSG[response?.data?.code];
      if (response?.code === STATUS_CODE.SUCCESS) {
        siteList(1, searchQuery);

        Toster(t("SITE_DELETED_SUCCESSFULLY"), "success");
      } else if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
      } else if (response?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(t(responseCode), "error");
      } else {
        Toster(t(responseCode), "error");
      }
    } catch (error) {
      console.error("Error deleting event:", error);
      Toster(t("ERROR_DELETING_SITE"), "error");
    } finally {
      setIsLoading(false);
      setShowModalDelete(false);
    }
  };

  const editSite = (item) => {
    setSiteItem(item);
    handleShowModal();
  };

  return (
    // <div>
    <div className="d-flex flex-column justify-content-between h-100 w-100">
      {isLoading && <Loader />}
      <div>
        <div className="">
          <Container>
            <Row className="align-items-center">
              <Col
                className="setSearchInputField"
                style={{ paddingBottom: "10px", paddingTop: "10px" }}
              >
                <CustomSearchField
                  setSearchQuery={(val) => {
                    setSearchQuery(val);
                    debouncedSearch(val);
                  }}
                />
              </Col>
              <Col>
                <div className="modelButtonPropwerty">
                  <Button
                    className="modalTextButton"
                    type="button"
                    onClick={handleShowModal}
                  >
                    {t("ADD_SITE")}
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div>
          {/*  <div className={styles.eventTable}>
            <Table className={styles.customTable} striped>
              <thead className={styles.tableHeadProperty}>
                <tr> */}
          <Container>
            <div className={styles?.eventTable}>
              <div className="setTableFromBottom">
                {siteData.length > 0 ? (
                  // <Table className="customTable" striped hover responsive>
                  //   <thead className="tableHeadProperty">
                  <Table className={styles.customTable} striped>
                    <thead className={styles.tableHeadProperty}>
                      <tr>
                        <th>{t("SITE_ID")}</th>
                        <th>{t("SITE_TITLE")}</th>
                        <th>{t("START_DATE")}</th>
                        <th>{t("INDEPENDICARE_REPRESENTATIVE")}</th>
                        <th>{t("READER_LOCATION")}</th>
                        <th>{t("NOTES")}</th>
                        <th>{t("STATUS")}</th>
                        <th
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {t("ACTION")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        // siteData?
                        siteData.map((item, index) => (
                          <tr key={index}>
                            <td style={tdAlignClass} className="tdtext">
                              {item.site_uid}
                            </td>
                            {/* <td style={tdAlignClass} className="tdtext">
                              {item.site_title}
                            </td> */}
                            {/* <td
                              style={{
                                ...tdAlignClass,
                              }}
                              className="tdtextNotes tdtext"
                            >
                              <div className="tdContentWrapper">
                                {item.site_id}
                              </div>
                            </td> */}
                            <td
                              style={{
                                ...tdAlignClass,
                              }}
                              className="tdtextNotes tdtext"
                            >
                              {/* {item.notes} */}
                              <div className="tdContentWrapper">
                                {item.site_title}
                              </div>
                            </td>
                            <td style={tdAlignClass} className="tdtext">
                              {moment(
                                item.start_date,
                                DATE_FORMATES.DD_MM_YY
                              ).format(DATE_FORMATES.MM_DD_YY)}
                            </td>
                            {/* <td style={tdAlignClass} className="tdtext">
                              {item.representative}
                            </td> */}
                            <td
                              style={{
                                ...tdAlignClass,
                              }}
                              className="tdtextNotes tdtext"
                            >
                              {/* {item.notes} */}
                              <div className="tdContentWrapper">
                                {item.representative}
                              </div>
                            </td>
                            {/* <td style={tdAlignClass} className="tdtext">
                              {item.reader_location}
                            </td> */}
                            <td
                              style={{
                                ...tdAlignClass,
                              }}
                              className="tdtextNotes tdtext"
                            >
                              {/* {item.notes} */}
                              <div className="tdContentWrapper">
                                {item.reader_location}
                              </div>
                            </td>

                            <td
                              style={{
                                ...tdAlignClass,
                              }}
                              className="tdtextNotes tdtext"
                            >
                              {/* {item.notes} */}
                              <div className="tdContentWrapper">
                                {item.notes}
                              </div>
                            </td>

                            <td style={tdAlignClass} className="tdtext">
                              {item.is_active === 1 ? "Active" : "Inactive"}
                            </td>
                            <td>
                              <Dropdown className="setParentDropdown">
                                <Dropdown.Toggle
                                  variant=""
                                  id="dropdown-basic"
                                  className="custom-dropdown-toggle p-0 border-0"
                                >
                                  <MoreHorizIcon />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <div
                                    className="d-flex justify-content-around dropdownTextProperty"
                                    onClick={async () => {
                                      await setSiteItem(item);
                                      handleShowModal();
                                    }}
                                  >
                                    <Dropdown.Item>{t("EDIT")}</Dropdown.Item>
                                    <Image
                                      src={edit}
                                      className="dropdownIcons"
                                    />
                                  </div>
                                  <div
                                    className="d-flex justify-content-around dropdownTextProperty"
                                    onClick={() => deleteSite(item.id)}
                                  >
                                    <Dropdown.Item>{t("DELETE")}</Dropdown.Item>
                                    <DeleteOutlineIcon className="iconColor" />
                                  </div>
                                  <div
                                    className="d-flex justify-content-around dropdownTextProperty"
                                    onClick={() => {
                                      activeInactiveFunction(item);
                                    }}
                                  >
                                    <Dropdown.Item>{`${
                                      item.is_active == 1
                                        ? `Inactive`
                                        : `Active`
                                    }`}</Dropdown.Item>
                                  </div>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        ))
                      }
                      {/* // : ""} */}
                    </tbody>
                  </Table>
                ) : (
                  <div className={styles.noRecord}>
                    <p>{t("NO_RECORD_FOUND")}</p>
                  </div>
                )}
              </div>
            </div>
          </Container>
        </div>
      </div>
      <Pagination
        count={addedCaregiverListCount}
        pageLimit={pageLimit}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />
      <Addsite
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        siteList={() => {
          siteList(1, "");
        }}
        currentPage={currentPage}
        siteItem={siteItem}
      />
      <AddSiteStatus
        showModal={showModalStatus}
        handleCloseModal={handleCloseModalStatus}
        selectedStatus={selectedStatus}
        siteList={() => {
          siteList(1, "");
        }}
      />

      <Modal show={showModalDelete} onHide={handleCloseModalDelete}>
        <Modal.Header className="d-flex justify-content-between">
          <p className={styles.Eventproperty}>{t("Alert")}</p>
          <CancelIcon
            className="closeIconProperty"
            onClick={handleCloseModalDelete}
          />
        </Modal.Header>
        <Modal.Body>
          <div className={styles.deleteClass}>
            <Image src={deleteImage} />
            <p>{t("ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_SITE")}</p>
          </div>
          <Button
            type="submit"
            className="AddSiteSubmitButton"
            onClick={deleteContent}
          >
            {t("OK")}
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default Site;
