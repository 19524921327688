import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import IndependicareApi from "../../Helpers/Api";

const initialState = {
  isLoading: false,
  userToken: null,
  currentUser: {},
  success: false,
  error: null,
  userType: "stakeholder",
  profileData: null,
};

export const userSignIn = createAsyncThunk(
  "user/userSignIn",
  async (data, { rejectWithValue }) => {
    try {
      const response = await IndependicareApi.signIn(data);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
// signup api cross check...
export const userSignUp = createAsyncThunk(
  "user/userSignUp",
  async (data, { rejectWithValue }) => {
    try {
      const response = await IndependicareApi.signUp(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// forget password
export const forgetPassword = createAsyncThunk(
  "user/forgetPassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await IndependicareApi.sendCode(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const userLogOut = createAsyncThunk(
  "user/userLogOut",
  async (data, { rejectWithValue }) => {
    // Ensure the correct parameter is passed
    try {
      const response = await IndependicareApi.logOut(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getProfile = createAsyncThunk(
  "user/getProfile",
  async (token, { rejectWithValue }) => {
    try {
      const response = await IndependicareApi.Profile(token);
      return response; // Return the profile data
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const getPerson = createAsyncThunk(
  "user/getPerson",
  async (params, { rejectWithValue }) => {
    try {
      const response = await IndependicareApi.detailPerson(params);

      return response; // Return the profile data
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const updateProfile = createAsyncThunk(
  "user/updateProfile",
  async ({ token, data }, { rejectWithValue }) => {
    try {
      const response = await IndependicareApi.updateProfile(token, data);
      return response.data; // Return updated profile data
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserType: (state, action) => {
      state.userType = action.payload;
    },

    clearUserState: (state) => {
      state.currentUser = {};
      state.isLoading = false;
      state.userToken = null;
      state.success = false;
    },
  },

  extraReducers: (builder) => {
    // user login=========================================
    builder.addCase(userSignIn.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(userSignIn.fulfilled, (state, action) => {
      const response = action.payload;

      state.isLoading = false;
      state.userToken = response?.data.token;
      state.currentUser = response?.data;
    });

    builder.addCase(userSignIn.rejected, (state, action) => {
      state.isLoading = false;
    });

    // user signup=========================================
    builder.addCase(userSignUp.pending, (state) => {
      state.isLoading = true;
      // state.error = null;
      // state.success = false;
    });
    builder.addCase(userSignUp.fulfilled, (state, action) => {
      const response = action.payload;

      state.isLoading = false;
      state.userToken = response?.data.token;
      state.currentUser = response?.data;
    });
    builder.addCase(userSignUp.rejected, (state, action) => {
      state.isLoading = false;
    });

    // user logout=========================================
    builder.addCase(userLogOut.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(userLogOut.fulfilled, (state) => {
      state.isLoading = false;
      state.userToken = null;
      state.currentUser = {};
      state.success = true;
    });

    builder.addCase(userLogOut.rejected, (state, action) => {
      state.isLoading = false;
    });

    // userProfile=========================================

    // Profile retrieval
    builder.addCase(getProfile.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.isLoading = false;
      state.profileData = action.payload.data;
    });

    builder.addCase(getProfile.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    // Person profile=========================================
    // Person retrieval
    builder.addCase(getPerson.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getPerson.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getPerson.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    // Profile update
    builder.addCase(updateProfile.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      state.isLoading = false;
      state.profileData = action.payload; // Update the profile data in the state
    });
    builder.addCase(updateProfile.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export const { userLogoutClear, setUserType, clearUserState } =
  userSlice.actions;
export default userSlice.reducer;
