import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const REACT_PROXYURL = "";

// for making unique id for every browser
if (!localStorage.getItem(process.env.REACT_APP_DEVICE_ID_KEY)) {
  const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];
  localStorage.setItem(
    process.env.REACT_APP_DEVICE_ID_KEY,
    uint32.toString(32)
  );
}

// for making unique token for every browser
if (!localStorage.getItem(process.env.REACT_APP_DEVICE_TOKEN_KEY)) {
  const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];
  localStorage.setItem(
    process.env.REACT_APP_DEVICE_TOKEN_KEY,
    uint32.toString(32)
  );
}

/** API Class
 * static clase trying together method used to get/send to the API.
 * There shouldn't be any frontend-specific stuff here, and there  shouldn't be any API-aware stuff else hre in the frontend...
 */
class IndependicareApi {
  //token which interact with the API will be stored here.
  static token;

  //required common header for each api calling.
  static commonHeaders = {
    accept: "application/json",
    "api-key": process.env.REACT_APP_API_KEY_PAIR,
    "device-token": localStorage.getItem(
      process.env.REACT_APP_DEVICE_TOKEN_KEY
    ),
    "device-id": localStorage.getItem(process.env.REACT_APP_DEVICE_ID_KEY),
    "device-type": process.env.REACT_APP_DEVICE_TYPE,
  };

  static async removeExtraSlashes(url) {
    return url.replace(/([^:]\/)\/+/g, "$1");
  }

  static async request(endpoint, data = {}, method = "get", header) {
    const url = await this.removeExtraSlashes(
      `${REACT_PROXYURL}${BASE_URL}${endpoint}`
    );
    const headers = { ...IndependicareApi.commonHeaders, ...header };

    const params = method === "get" ? data : {};
    try {
      const result = await axios({ url, method, data, params, headers });
      return result ? result.data : "There is no return for this route";
    } catch (err) {
      return err.response;
    }
  }

  // SignUp======
  static async signUp(data) {
    const userType = localStorage.getItem("usertype");
    let res = await this.request(`/${userType}/v1/signup`, data, "post");
    return res;
  }

  static async signIn(userData) {
    let res = await this.request(
      `/${userData.usertype}/v1/login`,
      userData.formData,
      "post"
    );
    return res;
  }

  //  SendCode=====
  static async sendCode(data) {
    const userType = localStorage.getItem("usertype");
    let res = await this.request(`/${userType}/v1/send-code`, data, "post");
    return res;
  }

  //  ResetPassword=====
  static async resetPassword(data) {
    const userType = localStorage.getItem("usertype");
    let res = await this.request(
      `/${userType}/v1/reset-password`,
      data,
      "post"
    );
    return res;
  }

  //  Country id, code
  static async countryList() {
    const userType = localStorage.getItem("usertype");
    let res = await this.request(`/${userType}/v1/country-list`, {}, "get");
    return res;
  }

  //  state id
  static async stateList(countryID) {
    const userType = localStorage.getItem("usertype");
    let res = await this.request(
      `/${userType}/v1/state-list?countryId=${countryID}`,
      {},
      "get"
    );
    return res;
  }

  //  Profile=====
  static async Profile(authToken) {
    const userType = localStorage.getItem("usertype");
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/${userType}/v1/profile`, {}, "get", header);
    return res;
  }

  //  UpdateProfile=====
  static async updateProfile(data, authToken) {
    const userType = localStorage.getItem("usertype");
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/${userType}/v1/update-profile`,
      data,
      "post",
      header
    );
    return res;
  }

  static async changePassword(userData) {
    let header = { "access-token": ` ${userData.token}` };

    let res = await this.request(
      `/${userData.usertype}/v1/change-password`,
      userData.formData,
      "post",
      header
    );
    return res;
  }

  //  SourceID=====
  static async sourceID() {
    let res = await this.request(
      "/caregiver/v1/caregiver-source-list",
      {},
      "get"
    );
    return res;
  }

  //  LogOut======
  static async logOut(data) {
    let header = { "access-token": ` ${data.token}` };
    let res = await this.request(
      `/${data.usertype}/v1/logout`,
      {},
      "post",
      header
    );
    return res;
  }

  //  Add Event Type======
  static async addEvent(data, authToken) {
    const userType = localStorage.getItem("usertype");
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/${userType}/v1/event-type`,
      data,
      "post",
      header
    );
    return res;
  }

  //List Event Type======
  static async fetchEvent(authToken, event_type) {
    const userType = localStorage.getItem("usertype");
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/${userType}/v1/fetch-event-type?event_type=${event_type}`,
      {},
      "get",
      header
    );
    return res;
  }

  ///Detail Event Type======
  static async detailEvent(authToken, id) {
    const userType = localStorage.getItem("usertype");
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/${userType}/v1/event-type-details/${id}`,
      {},
      "get",
      header
    );
    return res;
  }

  //update Event Type======
  static async updateEvent(data, authToken, eventTypeId) {
    const userType = localStorage.getItem("usertype");
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/${userType}/v1/update-event-type/${eventTypeId}`,
      data,
      "put",
      header
    );
    return res;
  }

  //delete Event Type======
  static async deleteEventType(authToken, eventTypeId) {
    const userType = localStorage.getItem("usertype");
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/${userType}/v1/delete-event-type/${eventTypeId}`,
      {},
      "delete",
      header
    );
    return res;
  }

  //Event Type Status======

  static async Eventstatus(authToken, eventTypeId) {
    const userType = localStorage.getItem("usertype");
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/${userType}/v1/event-type-status/${eventTypeId}`,
      {},
      "put",
      header
    );
    return res;
  }
  //Fetch Level======
  static async fetchLevel(authToken) {
    const userType = localStorage.getItem("usertype");
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/${userType}/v1/fetch-level`,
      {},
      "get",
      header
    );
    return res;
  }

  //Add Level Type======
  static async addLevel(data, authToken) {
    const userType = localStorage.getItem("usertype");
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`/${userType}/v1/level`, data, "post", header);
    return res;
  }

  //update Level ======
  static async updateLevel(data, authToken, levelId) {
    const userType = localStorage.getItem("usertype");
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/${userType}/v1/update-level/${levelId}`,
      data,
      "put",
      header
    );
    return res;
  }

  ///Detail Level ======
  static async detailLevel(authToken, levelId) {
    const userType = localStorage.getItem("usertype");
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/${userType}/v1/level-details/${levelId}`,
      {},
      "get",
      header
    );
    return res;
  }

  //delete Level ======
  static async deleteLevel(authToken, levelId) {
    const userType = localStorage.getItem("usertype");
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/${userType}/v1/delete-level/${levelId}`,
      {},
      "delete",
      header
    );
    return res;
  }

  // Level Status======

  static async Levelstatus(authToken, levelId) {
    const userType = localStorage.getItem("usertype");
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/${userType}/v1/level-status/${levelId}`,
      {},
      "put",
      header
    );
    return res;
  }

  //==============Add Time Mesaage======
  static async timeMessage(data, authToken) {
    const userType = localStorage.getItem("usertype");
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/${userType}/v1/message-type`,
      data,
      "post",
      header
    );
    return res;
  }

  //==================Fetch message======
  static async fetchMessage(authToken, messageType, listType = "") {
    const userType = localStorage.getItem("usertype");
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/${userType}/v1/fetch-message-type?list_type=${listType}&message_type=${messageType}`,
      {},
      "get",
      header
    );
    return res;
  }
  //update message ======
  static async updateTimeMessage(data, authToken, messageTypeId) {
    const userType = localStorage.getItem("usertype");
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/${userType}/v1/update-message-type/${messageTypeId}`,
      data,
      "put",
      header
    );
    return res;
  }

  ///Detail message ======
  static async detailMessage(authToken, messageTypeId) {
    const userType = localStorage.getItem("usertype");
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/${userType}/v1/message-type-details/${messageTypeId}`,
      {},
      "get",
      header
    );
    return res;
  }

  //delete Message ======
  static async deleteMessage(authToken, messageTypeId) {
    const userType = localStorage.getItem("usertype");
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/${userType}/v1/delete-message-type/${messageTypeId}`,
      {},
      "delete",
      header
    );
    return res;
  }

  // Message Status======

  static async TimeMessageStatus(authToken, messageTypeId) {
    const userType = localStorage.getItem("usertype");
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/${userType}/v1/message-type-status/${messageTypeId}`,
      {},
      "put",
      header
    );
    return res;
  }

  //  Add Relation======
  static async addRelation(data, authToken) {
    const userType = localStorage.getItem("usertype");
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/${userType}/v1/relation`,
      data,
      "post",
      header
    );
    return res;
  }

  //Fetch Relation======
  static async fetchRelation(authToken) {
    const userType = localStorage.getItem("usertype");
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/${userType}/v1/fetch-relation`,
      {},
      "get",
      header
    );
    return res;
  }
  //Fetch Permission======
  static async fetchPermission(authToken) {
    const userType = localStorage.getItem("usertype");
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/${userType}/v1/fetch-permission`,
      {},
      "get",
      header
    );
    return res;
  }

  ///Detail Relation======
  static async detailRelation(authToken, id) {
    const userType = localStorage.getItem("usertype");
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/${userType}/v1/relation-details/${id}`,
      {},
      "get",
      header
    );
    return res;
  }

  //delete Relation======
  static async deleteRelation(authToken, relationId) {
    const userType = localStorage.getItem("usertype");
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/${userType}/v1/delete-relation/${relationId}`,
      {},
      "delete",
      header
    );
    return res;
  }

  // Relation Status======

  static async relationStatus(authToken, relationId) {
    const userType = localStorage.getItem("usertype");
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/${userType}/v1/relation-status/${relationId}`,
      {},
      "put",
      header
    );
    return res;
  }

  //update Relation ======
  static async updateRelation(data, authToken, relationId) {
    const userType = localStorage.getItem("usertype");
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/${userType}/v1/update-relation/${relationId}`,
      data,
      "put",
      header
    );
    return res;
  }

  // Device Component APIs======

  // Get Mobile Device List======
  static async getMobileDevice(authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      "/stakeholder/v1/mobile-device-list",
      {},
      "get",
      header
    );
    return res;
  }

  // Save Device======
  static async saveDevice(data, authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      "/stakeholder/v1/save-device",
      data,
      "post",
      header
    );
    return res;
  }

  // Get Device List======
  static async getDeviceList(pageNo, authToken, key) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/stakeholder/v1/get-device?page_no=${pageNo}&search=${key}`,
      {},
      "get",
      header
    );
    return res;
  }
  // Get Added caregiver List======
  static async getAddedCareGiverList(pageNo, searchText, authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/stakeholder/v1/fetch-added-caregiverlist`,
      { page_no: pageNo, search: searchText },
      "get",
      header
    );
    return res;
  }

  // Get Added AssignPerson List 28Aug ======
  // static async getAssignPersonList(pageNo, searchText, authToken) {
  //   let header = { "access-token": ` ${authToken}` };
  //   let res = await this.request(
  //     `/stakeholder/v1/fetch-added-caregiverlist`,
  //     { page_no: pageNo, search: searchText },
  //     "get",
  //     header
  //   );
  //   return res;
  // }
  // Get caregiver List======
  static async getCareGiverList(pageNo, searchText, authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/stakeholder/v1/fetch-caregiver-list`,
      { page_no: pageNo, search: searchText },
      "get",
      header
    );
    return res;
  }

  // Get caregiver List======
  static async addCareGiverList(caregiverId, authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/stakeholder/v1/add-caregiver`,
      { caregiver_id: caregiverId },
      "post",
      header
    );
    return res;
  }
  // add assign Senior======
  static async assignSenior(formData, authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/stakeholder/v1/save-assign-senior`,
      formData,
      "post",
      header
    );
    return res;
  }
  ///stakeholder/v1/update-assigned-senior/{id}
  // add assign Senior======
  static async updateAssignSenior(formData, authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/stakeholder/v1/update-assigned-senior/${id}`,
      formData,
      "put",
      header
    );
    return res;
  }
  // remove caregiver List======
  static async removeCareGiverList(caregiverId, authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/stakeholder/v1/delete-added-caregiver/${caregiverId}`,
      {},
      "delete",
      header
    );
    return res;
  }

  // Device id API======
  static async getDeviceID(deviceId, authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/stakeholder/v1/device-details/${deviceId}`,
      {},
      "get",
      header
    );
    return res;
  }

  // Update Device API======
  static async updateDevice(data, deviceId, authToken) {
    var object = {};
    data.forEach(function (value, key) {
      object[key] = value;
    });
    var json = JSON.stringify(object);
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/stakeholder/v1/update-device/${deviceId}`,
      data,
      "put",
      header
    );
    return res;
  }

  // Delete Device API======
  static async deleteDevice(deviceId, authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/stakeholder/v1/delete-device/${deviceId}`,
      {},
      "delete",
      header
    );
    return res;
  }

  // Device Status API======
  static async deviceStatus(data, deviceId, authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/stakeholder/v1/device-status/${deviceId}`,
      data,
      "put",
      header
    );
    return res;
  }

  // get person list======
  static async getPersonList(authToken, pageno, key) {
    const userType = localStorage.getItem("usertype");
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/${userType}/v1/get-person?page_no=${pageno}&search=${key}`,
      {},
      "get",
      header
    );
    return res;
  }
  ///stakeholder/v1/fetch-addedcaregiver-detail/{id}
  // get person list======
  static async getCareGiverDetailsAPI(authToken, id) {
    const userType = localStorage.getItem("usertype");
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/${userType}/v1/fetch-addedcaregiver-detail/${id}`,
      {},
      "get",
      header
    );
    return res;
  }

  // get active person list======
  static async getActivPersonList(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/stakeholder/v1/get-person?page_no&list_type=active`,
      data,
      "get",
      header
    );
    return res;
  }
  // get Location list======
  static async getLocationType(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/stakeholder/v1/fetch-location-type`,
      data,
      "get",
      header
    );
    return res;
  }

  // get sub Location list======
  static async getSubLocationType(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/stakeholder/v1/fetch-sublocation-type`,
      data,
      "get",
      header
    );
    return res;
  }
  // get sub Location list======
  static async getPredefineds(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/stakeholder/v1/fetch-message-type`,
      data,
      "get",
      header
    );
    return res;
  }

  // list location list
  static async fetchLocationType(authToken, searchTerm) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/stakeholder/v1/fetch-location-type?search=${searchTerm}`,
      {},
      "get",
      header
    );
    return res;
  }

  // add location api
  static async addLocation(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/stakeholder/v1/location-type`,
      data,
      "post",
      header
    );
    return res;
  }

  // update  location api
  static async updateLocationType(authToken, data, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/stakeholder/v1/update-location-type/${id}`,
      data,
      "put",
      header
    );
    return res;
  }

  //update location status api
  static async updateStatusLocation(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/stakeholder/v1/update-location-status/${id}`,
      {},
      "put",
      header
    );
    return res;
  }

  // location delete api
  static async deleteLocationType(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/stakeholder/v1/delete-loaction-type/${id}`,
      {},
      "delete",
      header
    );
    return res;
  }

  //fetch location detail
  static async fetchLocationeDetail(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/stakeholder/v1/fetch-location-details/${id}`,
      {},
      "get",
      header
    );
    return res;
  }

  // fetch sub-location type
  static async fetchSubLocationType(authToken, searchTerm) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/stakeholder/v1/fetch-sublocation-type?search=${searchTerm}`,
      {},
      "get",
      header
    );
    return res;
  }

  // add sub location api
  static async addSubLocation(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/stakeholder/v1/sublocation-type`,
      data,
      "post",
      header
    );
    return res;
  }

  //fetch sub location detail
  static async fetchSubLocationDetail(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/stakeholder/v1/fetch-sublocation-details/${id}`,
      {},
      "get",
      header
    );
    return res;
  }

  // update sub location type api
  static async updateSubLocationType(authToken, data, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/stakeholder/v1/update-sublocation-type/${id}`,
      data,
      "put",
      header
    );
    return res;
  }

  // sub location delete api
  static async deleteSubLocationType(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/stakeholder/v1/delete-sublocation-type/${id}`,
      {},
      "delete",
      header
    );
    return res;
  }

  //update sub location status api
  static async updateStatusSubLocation(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/stakeholder/v1/update-sublocation-status/${id}`,
      {},
      "put",
      header
    );
    return res;
  }

  // get level list======
  static async getLevel(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/stakeholder/v1/fetch-level`,
      data,
      "get",
      header
    );
    return res;
  }
  // add tag ====
  static async addTag(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/stakeholder/v1/save-tag`,
      data,
      "post",
      header
    );
    return res;
  }
  // update tag ====
  static async updateTag(authToken, id, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/stakeholder/v1/update-tag/${id}`,
      data,
      "put",
      header
    );
    return res;
  }
  // get Tag list======
  static async getTagList(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/stakeholder/v1/get-tag`,
      data,
      "get",
      header
    );
    return res;
  }
  // static async getTagList(authToken,pageNo, key) {
  //   let header = { "access-token": ` ${authToken}` };
  //   let res = await this.request(
  //     `/stakeholder/v1/get-tag?page_no=${pageNo}&search=${key}`,
  //     {},
  //     "get",
  //     header
  //   );
  //   return res;
  // }

  // get Tag Details list======
  static async getTagDetails(authToken, id, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/stakeholder/v1/tag-details/${id}`,
      data,
      "get",
      header
    );
    return res;
  }
  // get person list======
  static async deleteTag(authToken, id, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/stakeholder/v1/delete-tag/${id}`,
      data,
      "delete",
      header
    );
    return res;
  }

  // save event ========
  static async saveEvent(data, authToken) {
    const userType = localStorage.getItem("usertype");
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/${userType}/v1/save-event`,
      data,
      "post",
      header
    );
    return res;
  }

  // get event====
  static async getEventList(pageNo, eventType, authToken, key) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/stakeholder/v1/get-event?page_no=${pageNo}&event_type=${eventType}&search=${key}`,
      {},
      "get",
      header
    );
    return res;
  }

  // delete event=====
  static async deleteEvent(eventId, authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/stakeholder/v1/delete-event/${eventId}`,
      {},
      "delete",
      header
    );
    return res;
  }
  // update event
  static async setEvent(data, id, authToken) {
    const userType = localStorage.getItem("usertype");
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/${userType}/v1/update-event/${id}`,
      data,
      "put",
      header
    );
    return res;
  }
  // event status event
  static async eventStatusUpdate(data, eventId, authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/stakeholder/v1/event-status/${eventId}`,
      data,
      "put",
      header
    );
    return res;
  }

  //  Add Person======
  static async addPerson(data, authToken, url, method) {
    const userType = localStorage.getItem("usertype");
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`/${url}`, data, method, header);
    return res;
  }

  //delete Person======
  static async deletePerson(authToken, personId) {
    const userType = localStorage.getItem("usertype");
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/${userType}/v1/delete-person/${personId}`,
      {},
      "delete",
      header
    );
    return res;
  }
  //Person Status======

  static async Personstatus(authToken, personId) {
    const userType = localStorage.getItem("usertype");
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/${userType}/v1/person-status/${personId}`,
      {},
      "put",
      header
    );
    return res;
  }

  ///Detail Person======
  static async detailPerson(params) {
    const userType = localStorage.getItem("usertype");
    let header = { "access-token": ` ${params.token}` };
    let res = await this.request(
      `/${userType}/v1/person-details/${params.id}`,
      {},
      "get",
      header
    );
    return res;
  }
  static async detailPersonForEdit(token, personId) {
    const userType = localStorage.getItem("usertype");
    let header = { "access-token": ` ${token}` };
    let res = await this.request(
      `/${userType}/v1/person-details/${personId}`,
      {},
      "get",
      header
    );
    return res;
  }
  //update person ==========
  static async updatePerson(data, authToken, personId) {
    const userType = localStorage.getItem("usertype");
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/${userType}/v1/update-person/${personId}`,
      data,
      "put",
      header
    );
    return res;
  }

  // add site ========
  static async addSite(data, authToken) {
    // const userType = localStorage.getItem("usertype");
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/stakeholder/v1/save-site`,
      data,
      "post",
      header
    );
    return res;
  }

  // get site====
  static async getSite(pageNo, authToken, key) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/stakeholder/v1/get-site?page_no=${pageNo}&search=${key}`,
      {},
      "get",
      header
    );
    return res;
  }
  // site status=======
  static async siteStatus(data, sitetId, authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/stakeholder/v1/site-status/${sitetId}`,
      data,
      "put",
      header
    );
    return res;
  }

  // delete site=====
  static async deleteSite(siteId, authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/stakeholder/v1/delete-site/${siteId}`,
      {},
      "delete",
      header
    );
    return res;
  }

  // update site=====
  static async updateSite(data, id, authToken) {
    // const userType = localStorage.getItem("usertype");
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/stakeholder/v1/update-site/${id}`,
      data,
      "put",
      header
    );
    return res;
  }

  // get stakeholder for caregiver list======
  static async getStakeholderList(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/caregiver/v1/fetch-stakeholder-list`,
      data,
      "get",
      header
    );
    return res;
  }
  //Content-Privacy-policy ======
  static async privacyPolicy(authToken, page_name) {
    const userType = localStorage.getItem("usertype");
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/${userType}/v1/fetch-content-page?page_name=${page_name}`,
      {},

      "get",
      header
    );
    return res;
  }
  // get stakeholder details for caregiver list======
  static async getStakeholderDetails(authToken, id, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/caregiver/v1/fetch-stakeholder-details/${id}`,
      data,
      "get",
      header
    );
    return res;
  }

  //  Delete Account ======
  static async deleteAccount(authToken) {
    const userType = localStorage.getItem("usertype");
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/${userType}/v1/delete-stakeholder-account`,
      {},
      "delete",

      header
    );
    return res;
  }

  //
  static async getAssignPersonList(pageNo, searchText, authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/caregiver/v1/fetch-senior-list`,
      { page_no: pageNo, search: searchText },
      "get",
      header
    );
    return res;
  }

  // delete assign senior=====
  static async deleteSeniorPerson(personId, authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/stakeholder/v1/delete-assigned-senior/${personId}`,
      {},
      "delete",
      header
    );
    return res;
  }
  // get person list for assign senior in caregiver ==========

  static async getPersonListAssignPerson(
    authToken,
    pageno,
    addedCaregiverId,
    key
  ) {
    const userType = localStorage.getItem("usertype");
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/${userType}/v1/get-person?page_no=${pageno}&added_id=${addedCaregiverId}&search=${key}`,
      {},
      "get",
      header
    );
    return res;
  }
}
export default IndependicareApi;
