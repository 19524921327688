import React from "react";
import {
  Container,
  Navbar,
  Nav,
  NavDropdown,
  NavLink,
  // Link,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../../Assests/Images/logo.png";
import styles from "../Header/Header.module.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function Header() {
  const { t } = useTranslation();
  return (
    <div className={styles.mainHeader}>
      <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="#home">
            <img src={logo} alt="Logo" className="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav>
              <Link to="/" className={styles.activeTab}>
                {t("HOME")}
              </Link>
              <Link to="" className={styles.inactiveTab}>
                {t("ABOUTUS")}
              </Link>
              <Link to="/signin" className={styles.signOut}>
                {t("SIGNIN/SIGNUP")}
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
export default Header;
